import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RelativeLinear as DumbRelativeLinear } from '../dumb/RelativeLinear/RelativeLinear';
import { Respect } from '../../store/ui/respect/respect';

export interface IRelativeLinearProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class RelativeLinear extends React.Component<IRelativeLinearProps> {
    render() {
        const {
            getSurvivalTimeFromPercent,
            medianSurvivalForSelectedBin,
        } = this.props.respect!;

        return (
            <DumbRelativeLinear
                medianSurvival={medianSurvivalForSelectedBin / 365}
                lowSurvival={getSurvivalTimeFromPercent(75) / 365}
                highSurvival={getSurvivalTimeFromPercent(25) / 365}
            />
        );
    }
}
