import './viewer-label.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import * as classNames from 'classnames';

export interface IViewerLabelProps {
    children: string;
    className?: string;
}

export const ViewerLabel: React.SFC<IViewerLabelProps> = observer(
    ({ children, className }: IViewerLabelProps) => {
        return (
            <label className={classNames('viewer-label', className)}>
                {children}
            </label>
        );
    },
);
