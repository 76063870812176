import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ContGraph } from '../../store/cont-graph';
import { ContGraph as DumbContGraph } from '../dumb/ContGraph/ContGraph';
import { Store } from '../../store/store';
import { RelativeRiskGraph } from '../../store/relative-risk-graph';
import { getLocalizedStringForId } from '../../strings/strings';

export interface IAgeContGraphProps {
    className: string;
    contGraph?: ContGraph;
    relativeRiskGraph?: RelativeRiskGraph;
}

@inject((stores: Store) => {
    return {
        contGraph: stores.contGraph,
        relativeRiskGraph: stores.relativeRiskGraph,
    };
})
@observer
export class AgeContGraph extends React.Component<IAgeContGraphProps> {
    render() {
        return (
            <DumbContGraph
                {...this.props.contGraph!.getContGraphData(
                    'age',
                    this.props.contGraph!.currentPredictor.label,
                    20,
                    80,
                )}
                minRR={this.props.relativeRiskGraph!.minAndMaxRelRisk[0]}
                maxRR={this.props.relativeRiskGraph!.minAndMaxRelRisk[1]}
                minX={20}
                xLabel={getLocalizedStringForId(
                    'rel_risk_cont_age_graph_x_axis',
                )}
                currentValue={this.props.contGraph!.ageSlider.currentValue}
                className={this.props.className}
                varyByLabel=""
            />
        );
    }
}
