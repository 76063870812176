import './group-table-one.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Card } from '@blueprintjs/core';
import { ViewerLabel } from '../ViewerLabel/ViewerLabel';

export interface IGroupTableOneProps {
    binNumber: number;
    numOfPeopleInBin: number;
    binPercentiles: [number, number];
    predictors: Array<{
        type: string;
        predictors: Array<CategoricalPredictor | IContinuousPredictorObj>;
    }>;
}

export const GroupTableOne: React.SFC<IGroupTableOneProps> = observer(
    ({
        binNumber,
        predictors,
        numOfPeopleInBin,
        binPercentiles,
    }: IGroupTableOneProps) => {
        return (
            <Card className="group-table-card">
                <h3>{`Characteristics of people in Bin ${binNumber}`}</h3>
                <h3>{`(n=${numOfPeopleInBin.toLocaleString()})`}</h3>
                <ViewerLabel>{`${binPercentiles[0]} to ${
                    binPercentiles[1]
                } percentile of population`}</ViewerLabel>
                <ViewerLabel>
                    Percent (%) of people unless stated otherwise
                </ViewerLabel>
                {predictors.map(type => {
                    return (
                        <div>
                            <ViewerLabel className="group-table-one__type-label">
                                {type.type}
                            </ViewerLabel>
                            <div className="group-table-one__predictors-container">
                                {type.predictors.map(predictor => {
                                    return 'categories' in predictor
                                        ? renderCategoricalPredictor(predictor)
                                        : renderContinuousPredictor(predictor);
                                })}
                            </div>
                        </div>
                    );
                })}
            </Card>
        );
    },
);

interface CategoricalPredictor {
    label: string;
    categories: Array<{
        label: string;
        percent: number;
    }>;
}

function renderCategoricalPredictor({
    label,
    categories,
}: CategoricalPredictor) {
    return (
        <div>
            {categories.length > 1 ? (
                <ViewerLabel>{label}</ViewerLabel>
            ) : (
                undefined
            )}
            {categories.map(category => {
                return (
                    <div className="group-table-one__predictor-container">
                        <ViewerLabel className="group-table-one__predictor-label group-table-one__categorical-predictor-label">
                            {category.label}
                        </ViewerLabel>
                        <ViewerLabel className="group-table-one__predictor-metric">
                            {formatCategoryPercentValue(category.percent)}
                        </ViewerLabel>
                    </div>
                );
            })}
        </div>
    );
}

function formatCategoryPercentValue(value: number | null): string {
    return value === null ? 'NA' : String(value);
}

interface IContinuousPredictorObj {
    label: string;
    units: string;
    mean: number;
    sd: number;
}

function renderContinuousPredictor({
    label,
    units,
    mean,
    sd,
}: IContinuousPredictorObj) {
    return (
        <div className="group-table-one__predictor-container">
            <ViewerLabel className="group-table-one__predictor-label">{`${label} (${units}) mean (SD)`}</ViewerLabel>
            <ViewerLabel className="group-table-one__predictor-metric ">{`${mean} (${sd})`}</ViewerLabel>
        </div>
    );
}
