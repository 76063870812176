import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Respect } from '../../store/ui/respect/respect';
import { GroupTableOne as DumbGroupTableCard } from '../dumb/GroupTableOne/GroupTableOne';

export interface IGroupTableOneProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class GroupTableOne extends React.Component<IGroupTableOneProps> {
    render() {
        const {
            groupSlider,
            tableInfoForCurrentBin,
            numOfPeopleInCurrentBin,
            currentBinPercentileRanges,
        } = this.props.respect!;

        return (
            <DumbGroupTableCard
                binNumber={groupSlider.currentValue}
                predictors={tableInfoForCurrentBin}
                numOfPeopleInBin={numOfPeopleInCurrentBin}
                binPercentiles={currentBinPercentileRanges}
            />
        );
    }
}
