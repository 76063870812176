import * as React from 'react';
import { InfoModalStore } from '../../store/info_modal';
import { InfoModal as DumbInfoModal } from '../dumb/InfoModal/InfoModal';
import { inject, observer } from 'mobx-react';

export interface IInfoModalProps {
    infoModal?: InfoModalStore;
}

@inject('infoModal')
@observer
export class InfoModal extends React.Component<IInfoModalProps> {
    render() {
        return (
            <DumbInfoModal
                isShown={this.props.infoModal!.isShown}
                onHideModal={this.props.infoModal!.hideModal}
            />
        );
    }
}
