import { Switch } from './switch';
import { Gender } from './gender';

export class GenderSwitch extends Switch {
    getCurrentGender(): Gender {
        return !this.isChecked ? Gender.Male : Gender.Female;
    }

    getCurrentGenderForEngine(): 'male' | 'female' {
        return !this.isChecked ? 'male' : 'female';
    }
}
