import './switch-control.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Switch, ISwitchProps } from '@blueprintjs/core';
import * as classNames from 'classnames';

export interface ISwitchControlProps {
    label: string;
    leftLabel: string;
    rightLabel: string;
    switchProps: ISwitchProps;
    className?: string;
}

export const SwitchControl: React.SFC<ISwitchControlProps> = observer(
    ({
        className,
        label,
        leftLabel,
        rightLabel,
        switchProps,
    }: ISwitchControlProps) => {
        return (
            <div className={classNames('switch-control', className)}>
                <h3 className="switch-control__label">{label}</h3>
                <div className="switch-control__switch-container">
                    <Switch
                        {...switchProps}
                        className="switch-control__switch"
                        inline={true}
                    />
                    <label className="switch-control__current-value-label">
                        {switchProps.checked ? rightLabel : leftLabel}
                    </label>
                </div>
            </div>
        );
    },
);
