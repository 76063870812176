import * as React from 'react';
import { AlgorithmPage } from '../AlgorithmPage/AlgorithmPage';
import { RelativeRiskGraph } from '../../smart/RelativeRiskGraph';
import { AgeContGraph } from '../../smart/AgeContGraph';
import { ExposureContGraph } from '../../smart/ExposureContGraph';
import { getLocalizedStringForId } from '../../../strings/strings';
import { AlgorithmPageControls } from '../../smart/AlgorithmPageControls';

export const CVDPoRTPage = () => {
    return (
        <AlgorithmPage
            title={getLocalizedStringForId('model_title')}
            RelativeRiskGraph={<RelativeRiskGraph />}
            AgeContGraph={
                <AgeContGraph className="algorithm-page__age-cont-graph" />
            }
            ExposureContGraph={
                <ExposureContGraph className="algorithm-page__exposure-cont-graph" />
            }
            AlgorithmPageControls={<AlgorithmPageControls />}
        />
    );
};
