import { observable, transaction } from 'mobx';

export class RangeSlider {
    @observable fromValue: number;
    @observable toValue: number;

    constructor(fromValue: number, toValue: number) {
        this.fromValue = fromValue;
        this.toValue = toValue;
    }

    updateValues = (newValues: [number, number]) => {
        transaction(() => {
            this.fromValue =
                newValues[0] > newValues[1] ? this.fromValue : newValues[0];
            this.toValue = newValues[1];
        });
    };
}
