import * as d3 from 'd3';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface IRectLinearProps {
    selectedGroup: number;
    numberOfBins: number;
}

@observer
export class ReactLinear extends React.Component<IRectLinearProps> {
    rectLinearPlot!: () => void;
    componentDidMount() {
        //@ts-ignore
        this.rectLinearPlot = drawRectLinear(
            Object.assign({}, this.props, {
                update: 0 as 0,
            }),
        );
        //@ts-ignore
        this.rectLinearPlot(this.props.selectedGroup);
    }
    componentDidUpdate() {
        //@ts-ignore
        this.rectLinearPlot(this.props.selectedGroup);
    }
    render() {
        return <div id="RectLinear" />;
    }
}

function drawRectLinear({
    update,
    numberOfBins,
}: IRectLinearProps & { update: 0 | 1 }) {
    var width = 800;
    var height = 70;
    const id = '#RectLinear';

    // This would be a reference array that is parsed from lime or PMML (TBA). Colours could be separately styled.
    // for CHESS algorithm.
    var catProps = [
        {
            level: 0,
            label: 'No health instability',
            width: 2,
            colour: '#feebe2',
        },
        {
            level: 1,
            label: 'Minimal',
            width: 1,
            colour: '#fcc5c0',
        },
        {
            level: 2,
            label: 'Low',
            width: 1,
            colour: '#fa9fb5',
        },
        {
            level: 3,
            label: 'Moderate',
            width: 1,
            colour: '#f768a1',
        },
        {
            level: 4,
            label: 'High',
            width: 1,
            colour: '#c51b8a',
        },
        {
            level: 5,
            label: 'Very high',
            width: 0.5,
            colour: '#7a0177',
        },
    ];
    // for RESPECT algorithm. 50 categories.
    //@ts-ignore
    var catProps = d3.range(numberOfBins).map(function(d, i) {
        return {
            level: d + 1,
            label: i === 0 ? 'Low' : i === numberOfBins - 1 ? 'High' : d + 1,
            width: 1,
            colour: d / 49,
        };
    });

    var color = d3.scaleLinear();
    // move color range to config file.

    var color = d3
        .scaleLinear()
        .domain([0, 0.5, 1])
        //@ts-ignore
        .range(['#008000', '#48D1CC', '#663399'])
        .interpolate(d3.interpolateHcl);

    // update = 1 for initial render or responsive (re-render for change in window size)
    if (update !== 1) {
        // sytling for selected category (This section of code to styling selected category could be cleaned...)
        //@ts-ignore
        var selected = function(selection) {
            selection
                .style('stroke-width', '6')
                .style('stroke', '#e41f57')
                .raise();
        };
        //@ts-ignore
        var notSelected = function(selection) {
            selection
                .style('opacity', '.8')
                .style('stroke-width', '0')
                .lower();
        };

        // text wrapping adaptation of https://bl.ocks.org/mbostock/7555321 -- requires a width, x and y attribute for each text element.
        //@ts-ignore
        function wrap(text) {
            text.each(function() {
                //@ts-ignore
                var text = d3.select(this),
                    words = text
                        .text()
                        .split(/\s+/)
                        .reverse(),
                    word,
                    //@ts-ignore
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    //@ts-ignore
                    x = text.attr(function(d) {
                        return xScale(d.start + d.width / 2);
                    }),
                    y = text.attr('y'),
                    width = parseFloat(text.attr('width')),
                    dy = parseFloat(text.attr('dy')),
                    dy = parseFloat(text.attr('dy'))
                        ? parseFloat(text.attr('dy'))
                        : 0;
                var tspan = text
                    .text(null)
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', dy + 'em');
                while ((word = words.pop())) {
                    //@ts-ignore
                    line.push(word);
                    //@ts-ignore
                    tspan.text(line.join(' '));
                    if (
                        //@ts-ignore
                        tspan.node().getComputedTextLength() > width &&
                        //@ts-ignore
                        line.length > 1
                    ) {
                        //@ts-ignore
                        line.pop();
                        //@ts-ignore
                        tspan.text(line.join(' '));
                        line = [word];
                        tspan = text
                            .append('tspan')
                            .attr('x', function(d) {
                                //@ts-ignore
                                return xScale(d.start + d.width / 2);
                            })
                            .attr('y', y)
                            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                            .text(word);
                    }
                    // identify if there are labels that are overlapping, even after text wrap
                    if (
                        //@ts-ignore
                        tspan.node().getComputedTextLength() > width &&
                        //@ts-ignore
                        line.length == 1
                    ) {
                        text.attr('class', 'label overlap');
                    }
                }
            });
        }

        // Initialize SVG
        // calculate starting range for each category, based on category width.
        catProps.reduce(function(a, b, i) {
            //@ts-ignore
            return (catProps[i].start = a + b.width);
        }, 0);
        //@ts-ignore
        catProps.forEach(function(d, i) {
            //@ts-ignore
            return (catProps[i].start = catProps[i].start - catProps[i].width);
        });
        var catMax = d3.max(catProps, function(d) {
            //@ts-ignore
            return d.start + d.width;
        });

        // Define margins
        var margin = {
                top: 15,
                right: 150,
                bottom: 20,
                left: 50,
            },
            width = width - margin.left - margin.right,
            height = height - margin.top - margin.top;

        // Define scales and domain
        var xScale = d3.scaleLinear().range([0, width]);

        xScale.domain([0, catMax]);

        // Define svg and initialize visualization
        var svg = d3
            .select(id)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            //  .style("background", "white")
            .append('g')
            .attr(
                'transform',
                'translate(' + margin.left + ',' + margin.top + ')',
            );

        // Add rectangluar linear categories
        svg
            .selectAll(id)
            .data(catProps)
            .enter()
            .append('rect')
            .attr('class', 'rectLinear')
            // .attr("id", function(d) {return "cat" + d.level})
            .attr('x', function(d) {
                //@ts-ignore
                return xScale(d.start);
            })
            .attr('width', function(d) {
                return xScale(d.width) - 2;
            })
            .attr('y', 0)
            .attr('rx', 4)
            .attr('height', height)
            // .style("fill", function(d) {return d.colour})
            .style('fill', function(d) {
                //@ts-ignore
                return color(d.colour);
            });

        // Add labels
        svg
            .selectAll(id)
            .data(catProps)
            .enter()
            .append('text')
            .attr('class', 'label')
            .attr('id', function(d) {
                return 'cat' + d.level;
            })
            .attr('width', function(d) {
                return xScale(d.width) - 1;
            })
            .attr('x', function(d) {
                //@ts-ignore
                return xScale(d.start + d.width / 2);
            })
            .attr('y', height + 10)
            .attr('dy', '.35em')
            .attr('text-anchor', 'middle')
            .text(function(d) {
                return d.label;
            })
            .call(wrap)
            //if there is text overlap, then show just the first and last label
            //@ts-ignore
            .attr('class', function(d, i) {
                if (
                    i > 0 &&
                    i < catProps.length - 1 &&
                    !d3.selectAll('.overlap').empty()
                ) {
                    return 'label hide';
                } else {
                    return 'label show';
                }
            });
    }

    //@ts-ignore
    return function(catSelected) {
        // update -- change style for selected category
        svg.selectAll('.rectLinear').attr('class', function(d) {
            //@ts-ignore
            if (catSelected == d.level) {
                return 'rectLinear selected';
            } else {
                return 'rectLinear notSelected';
            }
        });

        svg.selectAll('.selected').call(selected);
        svg.selectAll('.notSelected').call(notSelected);
    };
}
// No newline at end of file
