import './relative-risk-graph.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import * as d3 from 'd3';
import { getLocalizedStringForId } from '../../../strings/strings';

export interface IPlotDataObject {
    order: number;
    yAxisLabel: string;
    relRiskText: string;
    beforeVersusText: string | undefined;
    afterVersusText: string | undefined;
    referenceText: string;
    relRisk: number;
    exposurePrevalence: number;
    predictorName: string;
}

export interface IRelativeRiskGraphProps {
    plotData: IPlotDataObject[];
    age: number;
    exposureTo: number;
    showPrevalence: boolean;
    minRelRisk: number;
    maxRelRisk: number;
    xAxisLabel: string;
    onPredictorCircleClick: (predictorName: string) => void;
}

@observer
export class RelativeRiskGraph extends React.Component<
    IRelativeRiskGraphProps
> {
    graphContainer!: HTMLDivElement;
    updateGraph!: (newProps: IRelativeRiskGraphProps) => void;
    componentDidMount() {
        this.updateGraph = renderRelativeRisk(
            this.props.plotData,
            this.graphContainer,
            this.props.showPrevalence,
            this.props.minRelRisk,
            this.props.maxRelRisk,
            this.props.xAxisLabel,
            this.props.onPredictorCircleClick,
        );
        this.updateGraph(this.props);
    }
    componentDidUpdate() {
        return this.updateGraph(this.props);
    }
    render() {
        return (
            <div
                className="relative-risk-graph"
                ref={ref => {
                    this.graphContainer = ref as HTMLDivElement;
                }}
            />
        );
    }
}

function renderRelativeRisk(
    plotData: IPlotDataObject[],
    rootNode: HTMLElement,
    isPrevalenceToggled: boolean,
    minRR: number,
    maxRR: number,
    xAxisLabel: string,
    onPredictorCircleClick: (newPredictorName: string) => void,
) {
    // Set the dimensions of the canvas
    var margin = {
        top: 0,
        right: 20,
        bottom: 25,
        left: 80,
    };

    // Set domain and scales for relRisk. Test with linear scale, but final will have log scale (or option for log scale). Also start with fixed domain.
    var TempLabels = d3.select(rootNode).append('svg');

    //Calculate margin.left based on max width of text labels for y-axis
    var labels = TempLabels.selectAll('text')
        .attr('class', 'tempLabels')
        .data(plotData)
        .enter()
        .append('text')
        .text(function(d) {
            return d.yAxisLabel;
        });

    labels.each(function() {
        //@ts-ignore
        if (this.getBBox().width > margin.left) {
            //@ts-ignore
            margin.left = this.getBBox().width;
        }
    });
    TempLabels.remove(); // remove temporary label text after measuring the size.

    // calculate width and height of plot
    var chartWidth = rootNode.clientWidth;
    var width = Math.min(chartWidth - margin.left - margin.right, 450);
    var height = plotData.length * 25 - margin.top - margin.bottom;

    var xScale = d3
        //.scaleLinear()
        .scaleLog()
        .domain([minRR, maxRR]) // see script.js
        .range([0, width]);

    var Yscale = d3
        .scalePoint()
        .domain(
            plotData.map(function(d) {
                return d.yAxisLabel;
            }),
        )
        .range([height - margin.top - 50, margin.bottom]);

    var xAxis = d3.axisBottom(xScale).ticks(10, ',.2');
    // .tickValues([1.5])
    // .tickFormat(d3.format(".0s"))
    // .tickFormat(d3.formatPrefix(".1", 1))

    var yAxis = d3.axisLeft(Yscale);

    const svg = d3
        .select(rootNode)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // X-Axis
    svg.append('g')
        .attr('class', 'x-axis')
        .attr('transform', 'translate(0,' + (height - margin.bottom) + ')')
        //@ts-ignore
        .call(xAxis);

    // Y-Axis
    svg.append('g')
        .attr('class', 'y-axis')
        //@ts-ignore
        .call(yAxis);

    // Attach a line at RR = 1
    const relRiskOneLine = svg
        .append('line')
        .attr('class', 'axis')
        .style('stroke', 'Silver') // colour the line
        .attr('x1', xScale(1)) //x first end
        .attr('y1', margin.top) // y  first end of the line
        .attr('x2', xScale(1)) // x position of the second end of the line
        .attr('y2', height - margin.bottom); // y position of the second end of the line

    // X-axis label
    svg.append('g')
        //.attr("class", "y axis")
        .append('text')
        .attr('y', height - margin.bottom + 40)
        .attr('x', xScale(0.8))
        .style('font-size', '1.4em')
        .text(xAxisLabel);

    if (isPrevalenceToggled === true) {
        svg.append('g')
            .append('text')
            .attr('y', margin.top + 15)
            .attr('x', xScale(1) + 150)
            .style('font-size', '1em')
            .text('Circle size equals prevalence by age');
    }

    var div = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

    // initalize tooltips
    d3.select('.tooltip').remove;

    return function update({
        plotData,
        minRelRisk,
        maxRelRisk,
    }: IRelativeRiskGraphProps) {
        const xScale = d3
            //.scaleLinear()
            .scaleLog()
            .domain([minRelRisk, maxRelRisk]) // see script.js
            .range([0, width]);
        var xAxis = d3.axisBottom(xScale).ticks(10, ',.2');
        svg.selectAll('.x-axis').remove();
        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(0,' + (height - margin.bottom) + ')')
            //@ts-ignore
            .call(xAxis);

        var Yscale = d3
            .scalePoint()
            .domain(
                plotData.map(function(d) {
                    return d.yAxisLabel;
                }),
            )
            .range([height - margin.top - 50, margin.bottom]);
        var yAxis = d3.axisLeft(Yscale).ticks(plotData.length);
        svg.selectAll('.y-axis').remove();
        svg.append('g')
            .attr('class', 'y-axis')
            //@ts-ignore
            .call(yAxis);

        // crearte circles. Represents each hazard category
        const circles = svg.selectAll('circle').data(plotData, plotDatum => {
            return (plotDatum as IPlotDataObject).predictorName;
        });
        circles
            .enter()
            .append('circle')
            .attr('cx', function(d) {
                return xScale(d.relRisk);
            })
            .attr('cy', function(d) {
                return Yscale(d.yAxisLabel) as number;
            })
            .attr('r', function(d) {
                return Math.sqrt(d.exposurePrevalence / 3.14) * 2.5;
            })
            .attr('class', function(d) {
                if (d.referenceText) {
                    return 'relative-risk-graph__circle--reference';
                } else {
                    return 'relative-risk-graph__circle';
                }
            })
            .attr('z-index', '.2')
            .on('mouseover', function(d) {
                //@ts-ignore
                var g = d3
                    .select(this)
                    .node()
                    //@ts-ignore
                    .getBoundingClientRect();
                div.transition()
                    .duration(200)
                    .style('opacity', 1)
                    .style('top', g.top - 20 + 'px')
                    .style('left', g.right + 5 + 'px');

                var tooltip = `${d.relRiskText}<br/>${
                    d.beforeVersusText
                }<br/>${getLocalizedStringForId('circle_versus')}<br/>${
                    d.afterVersusText
                }`;
                if (d.referenceText) {
                    tooltip = `${d.referenceText}`;
                }

                div.html(tooltip);
            })
            .on('mouseout', function() {
                div.transition()
                    .duration(500)
                    .style('opacity', 0);
            })

            .on('click', function(d) {
                return d.referenceText
                    ? d.referenceText.length === 0
                        ? onPredictorCircleClick(d.predictorName)
                        : undefined
                    : onPredictorCircleClick(d.predictorName);
            });
        circles.exit().remove();
        circles
            .attr('cx', function(d) {
                return xScale(d.relRisk);
            })
            .attr('cy', function(d) {
                return Yscale(d.yAxisLabel) as number;
            })
            .attr('r', function(d) {
                return Math.sqrt(d.exposurePrevalence / 3.14) * 2.5;
            });

        relRiskOneLine
            .attr('x1', xScale(1)) //x first end
            .attr('y1', margin.top) // y  first end of the line
            .attr('x2', xScale(1)) // x position of the second end of the line
            .attr('y2', height - margin.bottom);
    };
}
