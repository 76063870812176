import './algorithm-viewer-app.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

export interface IAlgorithmViewerAppProps {
    children?: React.ReactChild[];
}

export const AlgorithmViewerApp: React.SFC<
    IAlgorithmViewerAppProps
> = withRouter(
    // We need the withRouter so that route changes are detected
    //@ts-ignore
    observer(({ children }: IAlgorithmViewerAppProps) => {
        return <div>{children}</div>;
    }),
);
