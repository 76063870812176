import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Respect } from '../../store/ui/respect/respect';
import { RespectApp as DumbRespectApp } from '../dumb/RespectApp/RespectApp';

export interface IRespectAppProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class RespectApp extends React.Component<IRespectAppProps> {
    render() {
        const { isLoading } = this.props.respect!;

        return <DumbRespectApp isLoading={isLoading} />;
    }
}
