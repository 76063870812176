import { observable } from 'mobx';

export class Slider {
    @observable currentValue: number;

    constructor(currentValue: number) {
        this.currentValue = currentValue;
    }

    setCurrentValue = (newCurrentValue: number) => {
        this.currentValue = newCurrentValue;
    };
}
