import { observable, computed } from 'mobx';
import { modelsSingleton, ModelsSingleton } from '../domain/models-singleton';
import { ConfigSingleton } from '../domain/config-singleton';

export class AlgorithmSelectorPage {
    @observable modelsSingleton: ModelsSingleton = modelsSingleton;

    @computed
    get modelNamesAndLoadingStatus(): Array<{
        name: string;
        isLoading: boolean;
    }> {
        const modelNames = Object.keys(ConfigSingleton.models);
        modelNames.push('cvdport-r');

        return modelNames.map(name => {
            return {
                name: name,
                isLoading:
                    name === 'respect'
                        ? modelsSingleton.respect === false
                        : false,
            };
        });
    }
}
