import './algorithm-page.scss';

import * as React from 'react';
import { InfoModal } from '../../smart/InfoModal';
import { observer } from 'mobx-react';
import * as classnames from 'classnames';

export interface IAlgorithmPageProps {
    title: string;
    RelativeRiskGraph: React.ReactElement<any>;
    AgeContGraph: React.ReactElement<any>;
    ExposureContGraph: React.ReactElement<any>;
    AlgorithmPageControls: React.ReactElement<any>;
    className?: string;
}

export const AlgorithmPage: React.SFC<IAlgorithmPageProps> = observer(
    ({
        title,
        RelativeRiskGraph,
        AgeContGraph,
        ExposureContGraph,
        AlgorithmPageControls,
        className,
    }: IAlgorithmPageProps) => {
        return (
            <div className={classnames('algorithm-page', className)}>
                <InfoModal />
                <h1 className="algorithm-page__name">{title}</h1>
                <div className="algorithm-page__graphs-and-controls-container">
                    <div className="algorithm-page__graphs-container">
                        {RelativeRiskGraph}
                        <div className="algorithm-page__cont-graphs-container">
                            {AgeContGraph}
                            {ExposureContGraph}
                        </div>
                    </div>
                    {AlgorithmPageControls}
                </div>
            </div>
        );
    },
);
