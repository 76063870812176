import * as React from 'react';
import { observer } from 'mobx-react';
import * as d3 from 'd3';

export interface IIconGraphProps {
    survival: number;
}

@observer
export class IconGraph extends React.Component<IIconGraphProps> {
    iconPlot!: () => void;
    componentDidMount() {
        //@ts-ignore
        this.iconPlot = drawIconMatrix(25, 4, '#CatesPlot', 'person', 800);
        //@ts-ignore
        this.iconPlot(this.props.survival);
    }
    componentDidUpdate() {
        //@ts-ignore
        this.iconPlot(this.props.survival);
    }
    render() {
        return <div id="CatesPlot" />;
    }
}

//@ts-ignore
function drawIconMatrix(columns, rows, id, symbol, width) {
    // outcomeRisk = risk (percent). i.e .40 percent probablity of suriving 6 months outcomerisk = 0.40
    // ie. use probablity of surviving to "will you live to see it" time. (circle on surival plot)
    var margin = {
        top: 15,
        right: 180,
        bottom: 15,
        left: 50,
    };

    //Space between icons. Percent of icon size e.g. xGap = 0.5 to have spacing half the width of the icon.
    var xGap = 0.2;
    var yGap = 0.2;

    var icon = {
        person:
            'M10, 18 C14, 18 18, 14 18, 9 C18, 4 17, 0 10, 0 C4 ,0 3, 4 3, 9 C3, 14 6,18 10, 18 L10, 18 L10, 18 L10,18 Z M20, 26 C20, 21 19, 20 13, 19 C13, 19 12,20 10,20 C8, 20 7, 19 7, 19 C1, 20 0, 22 0, 26 C0, 26 0, 26 0, 26 C0, 26 0, 26 0, 26 C0, 26 0, 29 10, 29 C18, 29 20, 26 20, 26 C20, 26 20, 26 20, 26 C20, 26 20, 26 20, 26 L20, 26 L20, 26 L20, 26 Z',
        circle: 'M -1, 0 a 1,1 0 1,0 2,0 a 1,1 0 1,0 -2,0',
        cross: d3.symbol().type(d3.symbolCross),
        square: d3.symbol().type(d3.symbolSquare),
    };
    //alternatively, can use: d3.symbol().type(d3.symbolCricle);
    // var symbol = "person";
    //generate a array for the total number of required elements

    var iconArray = d3.range(columns * rows);

    // var percentRisk = (columns*rows) * (outcomeRisk / 100);
    // .map(function(d,i) {  return (i < percentRisk ? 1 : 0) });
    // if (random === 1) { return d3.shuffle(initArray); };
    //create svg element

    var svg = d3
        .select(id)
        .append('svg')
        .attr('width', width);

    //define an icon store it in svg <defs> elements as a reusable component. <defs> not displayed on the SVG
    svg
        .append('g')
        .append('path')
        .attr('id', 'icon')
        //@ts-ignore
        .attr('d', icon[symbol]);

    // height height and width of the icon or shape that will be used in the iconMatrix.
    //@ts-ignore
    var isotypeIcon = svg
        .select('#icon')
        .node()
        //@ts-ignore
        .getBBox();
    svg.selectAll('#icon').remove();

    // calculate the size of icon matrix
    var widthIconUnits = columns + (columns - 1) * xGap;
    var heightIconUnits = rows + (rows - 1) * yGap;

    // calculate the scale to enlarge or reduce the size of the isotypeIcon to fit the width of SVG container.
    var scale =
        (width - (margin.left + margin.right)) /
        widthIconUnits /
        isotypeIcon.width;

    // first calculate the aspect ratio of entire icon matrix
    var widthUnits = widthIconUnits * isotypeIcon.width;
    var heightUnits = heightIconUnits * isotypeIcon.height;
    var height =
        (width - (margin.left + margin.right)) * (heightUnits / widthUnits) +
        margin.top +
        margin.bottom;
    svg.attr('height', height);

    // adjust margin for an icon offsets, if needed
    margin.left = margin.left - isotypeIcon.x * scale;
    margin.top = margin.top - isotypeIcon.y * scale;

    //background rectangle -- if needed for styling.
    // svg.append("rect")
    // .attr("id", id + "Background")
    //   .attr("width", width)
    //   .attr("height", height);

    //create group element and create an svg <use> element for each icon
    svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .selectAll('path')
        .data(iconArray)
        .enter()
        .append('path')
        //@ts-ignore
        .attr('d', icon[symbol])
        .attr('transform', 'scale(' + scale + ')')
        .attr('transform', function(d) {
            return (
                'translate(' +
                (d % columns) * isotypeIcon.width * (1 + xGap) +
                ',' +
                Math.floor(d / columns) * isotypeIcon.height * (1 + yGap) +
                ')'
            );
        })
        .classed('icon', true);

    // --------- Update ------------ //
    // functions update to shade icons for outcome risk, or whatever
    //@ts-ignore
    return function(outcomeRisk) {
        svg.selectAll('.icon').attr('class', function(d) {
            if (d < outcomeRisk) {
                return 'icon iconSelected';
            } else {
                return 'icon iconPlain';
            }
        });
    };
}
