import { Model } from '@ottawamhealth/pbl-calculator-engine/lib/engine/model/model';
import { InteractionCovariate } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/covariate/interaction-covariate/interaction-covariate';
import { Data } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data';
import { TableOne } from './table-one';
import { VisualConfig } from './visual-config';

const VisualConfigJson = require('../../../dist/data/mportv2/config.json');
const PBLSpecGeneral: PBLSpecGeneralObj[] = require('../../../dist/data/mportv2/pbl-spec-general.json');
const ModelJson = require('../../../dist/data/mportv2/model.json');
const TableOneJson = require('../../../dist/data/mportv2/table-one.json');

export class MPoRTSingleton {
    static visualConfig = new VisualConfig(VisualConfigJson);
    static generalPblSpec = PBLSpecGeneral;
    static model = new Model(ModelJson);
    static tableOne = new TableOne(TableOneJson);

    static findGeneralSpecForPredictor(predictor: string) {
        return MPoRTSingleton.generalPblSpec.find(generalPblSpecRow => {
            return generalPblSpecRow.Name === predictor;
        })!;
    }

    static getRelativeRiskForPredictor(
        predictor: string,
        sex: 'male' | 'female',
        age: number,
        exposureTo: number,
        exposureFrom: number,
    ): number {
        const ageDatum = {
            name: 'age',
            coefficent: age,
        };

        const algorithmForCurrentSex = this.model.getAlgorithmForData([
            {
                name: 'sex',
                coefficent: sex,
            },
        ]);

        const covariate = algorithmForCurrentSex.covariates.find(covariate => {
            return covariate.name === predictor;
        })!;
        if (!covariate) {
            console.log(predictor);
        }

        const generalSpecForPredictor = MPoRTSingleton.findGeneralSpecForPredictor(
            predictor,
        );

        const visualConfigObj = MPoRTSingleton.visualConfig.getVisualConfigJsonObjForPredictor(
            predictor,
            sex,
        );

        const percentilePredictors = visualConfigObj
            ? visualConfigObj.percentilePredictors
                ? visualConfigObj.percentilePredictors
                : []
            : [];

        const percentilePredictorsData: Data = percentilePredictors.map(
            percentilePredictor => {
                return {
                    name: percentilePredictor,
                    coefficent: MPoRTSingleton.tableOne.getPredictorValue(
                        predictor,
                        [
                            {
                                name: 'RiskPercentile',
                                value: `${exposureTo}`,
                            },
                            {
                                name: percentilePredictor,
                                value: 'NA',
                            },
                        ],
                        sex,
                    ),
                };
            },
        );

        if (generalSpecForPredictor.variableType === 'categorical') {
            const allCovariatesInGroup = algorithmForCurrentSex
                .getCovariatesForGroup(
                    covariate.groups.filter(group => {
                        return group !== 'AGE';
                    })[0],
                )
                .filter(currentCovariate => {
                    return [
                        'SmokeCurrentC_cat',
                        'SmokeFormer0to5C_cat',
                        'SmokeFormer5PlusC_cat',
                    ].indexOf(covariate.name) !== -1
                        ? currentCovariate.name === 'PackYearsC_rcs1'
                            ? false
                            : true
                        : true;
                })
                .filter(covariate => {
                    return covariate instanceof InteractionCovariate === false;
                })
                .filter(covariate => {
                    return covariate.customFunction ? false : true;
                });

            const inputRisk = algorithmForCurrentSex.getRiskToTime(
                (allCovariatesInGroup.map(covariate => {
                    const derivedField =
                        ['DepIndHighC_cat', 'DepIndModC_cat'].indexOf(
                            covariate.name,
                        ) === -1
                            ? covariate.derivedField!.derivedFrom[0]
                            : covariate;

                    return {
                        name: derivedField.name,
                        coefficent: covariate.name === predictor ? 1 : 0,
                    };
                }) as Data).concat(percentilePredictorsData, ageDatum),
            );
            const relativeRisk = algorithmForCurrentSex.getRiskToTime(
                (allCovariatesInGroup.map(covariate => {
                    return {
                        name: covariate.name,
                        coefficent: 0,
                    };
                }) as Data).concat(
                    percentilePredictors.map(percentilePredictor => {
                        return {
                            name: percentilePredictor,
                            coefficent: 0,
                        };
                    }),
                    ageDatum,
                ),
            );

            return inputRisk / relativeRisk;
        } else {
            const inputRisk = algorithmForCurrentSex.getRiskToTime([
                ageDatum,
                {
                    name: predictor,
                    coefficent: this.tableOne.getPredictorValue(
                        predictor,
                        [
                            {
                                name: 'RiskPercentile',
                                value: `${exposureTo}`,
                            },
                            {
                                name: 'NA',
                                value: 'NA',
                            },
                        ],
                        sex,
                    ),
                },
            ]);
            const relativeRisk = algorithmForCurrentSex.getRiskToTime([
                ageDatum,
                {
                    name: predictor,
                    coefficent: this.tableOne.getPredictorValue(
                        predictor,
                        [
                            {
                                name: 'RiskPercentile',
                                value: `${exposureFrom}`,
                            },
                            {
                                name: 'NA',
                                value: 'NA',
                            },
                        ],
                        sex,
                    ),
                },
            ]);

            return inputRisk / relativeRisk;
        }
    }
}

interface PBLSpecGeneralObj {
    Name: string;
    displayName: string;
    variableType: 'categorical' | 'continuous';
}
