import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Respect as RespectStore } from '../../store/ui/respect/respect';
import { RespectControls as DumbRespectControls } from '../dumb/RespectControls/RespectControls';
import { CoxSurvivalAlgorithm } from '@ottawamhealth/pbl-calculator-engine/lib/engine/algorithm/regression-algorithm/cox-survival-algorithm/cox-survival-algorithm';
import { Visualizations } from '../../store/ui/respect/visualizations';

export interface IRespectControlsProps {
    respect?: RespectStore;
}

@inject('respect')
@observer
export class RespectControls extends React.Component<IRespectControlsProps> {
    render() {
        const {
            groupSlider,
            modelsSingleton,
            toggleSecondaryVisualization,
            activeSecondaryVisualizationNames,
        } = this.props.respect!;
        const { respect } = modelsSingleton;

        return (
            <DumbRespectControls
                groupSliderValue={groupSlider.currentValue}
                onGroupSliderChange={groupSlider.setCurrentValue}
                groupSliderMax={
                    Object.keys(
                        (respect as CoxSurvivalAlgorithm).bins!.binsData,
                    ).length
                }
                groupSliderMin={1}
                toggleVisualization={visualization => {
                    toggleSecondaryVisualization(Visualizations[visualization]);
                }}
                activeVisualizations={activeSecondaryVisualizationNames}
            />
        );
    }
}
