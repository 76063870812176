import { MPoRTSingleton } from '../domain/mport-singleton';
import { PortPageControls } from '../port-page-controls';
import { observable } from 'mobx';
import { autobind } from 'core-decorators';

@autobind
export class MPoRTPage {
    static mportSingleton = MPoRTSingleton;
    @observable
    controls = new PortPageControls();
    @observable
    currentPredictor: string = 'SmokeCurrentC_cat';

    setCurrentPredictor(currentPredictor: string) {
        this.currentPredictor = currentPredictor;
    }
}
export const mportPageSingleton = new MPoRTPage();
