import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { AlgorithmSelectorPage as AlgorithmSelectorPageStore } from '../../store/ui/algorithm-selector-page';
import { AlgorithmSelectorPage as DumbAlgorithmSelectorPage } from '../dumb/AlgorithmSelectorPage/AlgorithmSelectorPage';

export interface IAlgorithmSelectorPageProps {
    algorithmSelectorPage?: AlgorithmSelectorPageStore;
}

@inject('algorithmSelectorPage')
@observer
export class AlgorithmSelectorPage extends React.Component<
    IAlgorithmSelectorPageProps
> {
    render() {
        return (
            <DumbAlgorithmSelectorPage
                models={
                    this.props.algorithmSelectorPage!.modelNamesAndLoadingStatus
                }
            />
        );
    }
}
