export class TableOne {
    tableOne: {
        [sex in 'male' | 'female']: {
            [groupBy_1: string]: {
                [groupByValue_1: string]: {
                    [groupBy_2: string]: {
                        [predictor: string]: TableOneObj;
                    };
                };
            };
        }
    };

    constructor(tableOneJson: TableOneObj[]) {
        this.tableOne = tableOneJson.reduce(
            (indexedTableOne, tableOneObj) => {
                if (!indexedTableOne[tableOneObj.sex]) {
                    indexedTableOne[tableOneObj.sex] = {};
                }
                if (!indexedTableOne[tableOneObj.sex][tableOneObj.groupBy_1]) {
                    indexedTableOne[tableOneObj.sex][
                        tableOneObj.groupBy_1
                    ] = {};
                }
                if (
                    !indexedTableOne[tableOneObj.sex][tableOneObj.groupBy_1][
                        tableOneObj.groupByValue_1
                    ]
                ) {
                    indexedTableOne[tableOneObj.sex][tableOneObj.groupBy_1][
                        tableOneObj.groupByValue_1
                    ] = {};
                }
                if (
                    !indexedTableOne[tableOneObj.sex][tableOneObj.groupBy_1][
                        tableOneObj.groupByValue_1
                    ][tableOneObj.groupBy_2]
                ) {
                    indexedTableOne[tableOneObj.sex][tableOneObj.groupBy_1][
                        tableOneObj.groupByValue_1
                    ][tableOneObj.groupBy_2] = {};
                }

                indexedTableOne[tableOneObj.sex][tableOneObj.groupBy_1][
                    tableOneObj.groupByValue_1
                ][tableOneObj.groupBy_2][tableOneObj.predictor] = tableOneObj;

                return indexedTableOne;
            },
            {} as TableOne['tableOne'],
        );
    }

    getPredictorValue(
        predictor: string,
        groupedBy: Array<{
            name: string;
            value: string;
        }>,
        sex: 'male' | 'female',
    ): number {
        return Number(
            this.tableOne[sex][groupedBy[0].name][groupedBy[0].value][
                groupedBy[1].name
            ][predictor].Percentile_50,
        );
    }
}

interface TableOneObj {
    sex: 'male' | 'female';
    predictor: string;
    groupBy_1: string;
    groupByValue_1: string;
    groupBy_2: string;
    groupByValue_2: string;
    Percentile_50: string;
}
