import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { BeeSwarmPlot as DumbBeeSwarmPlot } from '../dumb/BeeSwarmPlot/BeeSwarmPlot';
import { Respect } from '../../store/ui/respect/respect';

export interface IBeeSwarmPlotProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class BeeSwarmPlot extends React.Component<IBeeSwarmPlotProps> {
    render() {
        const {
            beeSwarmPlotData,
            groupSlider,
            survivalTime,
        } = this.props.respect!;

        return (
            <DumbBeeSwarmPlot
                data={beeSwarmPlotData}
                selectedGroup={groupSlider.currentValue}
                selectedSurvivalTime={survivalTime}
            />
        );
    }
}
