const PrevelanceByAgeConfig: IPrevalenceByAgeConfigObject[] = require('../../dist/data/age/cvdport-age.json');

import {
    VisualConfConfig,
    IVisualConfObject,
    PercentileConfig,
    Algorithms,
} from './json';
import { Gender } from './gender';
import { getLocalizedStringForId } from '../strings/strings';
import { observable, computed } from 'mobx';
import { RangeSlider } from './range-slider';
import { GenderSwitch } from './gender-switch';
import { PrevalenceSwitch } from './prevalence-switch';
import { Slider } from './slider';
import { minBy, maxBy } from 'lodash';
import { IPlotDataObject } from '../components/dumb/RelativeRiskGraph/RelativeRiskGraph';
import { Graph } from './graph';

export class RelativeRiskGraph extends Graph {
    @observable prevalenceSwitch: PrevalenceSwitch;

    constructor(
        ageSlider: Slider,
        exposureSlider: RangeSlider,
        genderSwitch: GenderSwitch,
        prevalenceSwitch: PrevalenceSwitch,
    ) {
        super(ageSlider, exposureSlider, genderSwitch);

        this.prevalenceSwitch = prevalenceSwitch;
    }

    @computed
    get relativeRiskGraphData(): IPlotDataObject[] {
        let exposureTo = this.exposureSlider.toValue;
        let exposureFrom = this.exposureSlider.fromValue;
        const gender = this.genderSwitch.getCurrentGender();
        const age = this.ageSlider.currentValue;

        return this.getPlotData(age, exposureTo, exposureFrom, gender);
    }

    @computed
    get minAndMaxRelRisk() {
        const plotData = this.getPlotData(
            20,
            90,
            10,
            this.genderSwitch.getCurrentGender(),
        );

        const minRelRisk = (minBy(plotData, function(plotDatum) {
            return plotDatum.relRisk;
        }) as IPlotDataObject).relRisk;
        const maxRelRisk = (maxBy(plotData, function(plotDatum) {
            return plotDatum.relRisk;
        }) as IPlotDataObject).relRisk;

        return [minRelRisk, maxRelRisk];
    }

    private getPlotData = (
        age: number,
        exposureTo: number,
        exposureFrom: number,
        gender: Gender,
    ): IPlotDataObject[] => {
        const isPrevalenceToggled = this.prevalenceSwitch.showPrevalence();
        const plotPredictors = VisualConfConfig[gender];
        const algorithmForCurrentGender = Algorithms[gender];

        var plotData = [];
        for (let i = 0; i < plotPredictors.length; i++) {
            var reference = [];
            var input = [];

            for (let j = 0; j < plotPredictors[i].reference.length; j++) {
                reference.push(
                    this.getDatumForInputOrReferenceObject(
                        plotPredictors[i].reference[j],
                        {
                            age,
                            exposureFrom,
                            exposureTo,
                        },
                    ),
                );
                input.push(
                    this.getDatumForInputOrReferenceObject(
                        plotPredictors[i].input[j],
                        {
                            age,
                            exposureFrom,
                            exposureTo,
                        },
                    ),
                );
            }

            var inputRisk = algorithmForCurrentGender.evaluate(input);
            var referenceRisk = algorithmForCurrentGender.evaluate(reference);

            var relRisk = inputRisk / referenceRisk;

            let exposurePrevalence = 40;

            //exposure prevalence by age
            if (isPrevalenceToggled === true) {
                exposurePrevalence =
                    PrevelanceByAgeConfig[age - 20][gender][
                        plotPredictors[i].name
                    ];
            }

            // TO DO: plot labels diereclty from plotPredictors
            plotData.push({
                order: plotPredictors[i].order,
                yAxisLabel: plotPredictors[i].label,
                relRiskText: getLocalizedStringForId('circle_rel_risk_value', [
                    relRisk.toFixed(2),
                ]),
                beforeVersusText: this.getBeforeVersusText(
                    plotPredictors[i],
                    age,
                    gender,
                    exposureTo,
                ),
                afterVersusText: this.getAfterVersusText(
                    plotPredictors[i],
                    gender,
                    exposureFrom,
                ),
                referenceText: getLocalizedStringForId(plotPredictors[i]
                    .referenceStringId as any),
                relRisk: relRisk,
                exposurePrevalence,
                predictorName: plotPredictors[i].name,
            });
        }

        plotData.sort(function(a, b) {
            // Assign the order that the risk will be plotted.
            return b.order - a.order;
        });

        return plotData;
    };

    private getBeforeVersusText = (
        predictorVisualConfObject: IVisualConfObject,
        currentAge: number,
        currentGender: Gender,
        exposureTo: number,
    ): string | undefined => {
        if (predictorVisualConfObject.relRiskStringId) {
            return getLocalizedStringForId(
                predictorVisualConfObject.relRiskStringId as any,
                [
                    currentAge,
                    currentGender,
                    predictorVisualConfObject.percentileTo
                        ? PercentileConfig[exposureTo][currentGender][
                              predictorVisualConfObject.percentileTo
                          ]
                        : '',
                    exposureTo,
                ],
            );
        } else {
            return undefined;
        }
    };

    private getAfterVersusText = (
        predictorVisualConfObject: IVisualConfObject,
        currentGender: Gender,
        exposureFrom: number,
    ): string | undefined => {
        if (predictorVisualConfObject.relRiskStringId) {
            return getLocalizedStringForId(
                predictorVisualConfObject.versusRiskStringId as any,
                [
                    this.genderSwitch.getCurrentGender(),
                    predictorVisualConfObject.percentileFrom
                        ? PercentileConfig[exposureFrom][currentGender][
                              predictorVisualConfObject.percentileFrom
                          ]
                        : '',
                    exposureFrom,
                ],
            );
        } else {
            return undefined;
        }
    };
}

interface IPrevalenceByAgeConfigObject {
    age: number;
    male: { [index: string]: number };
    female: { [index: string]: number };
}
