import * as React from 'react';
import { observer } from 'mobx-react';
import * as d3 from 'd3';

export interface IRelativeLinearProps {
    medianSurvival: number;
    highSurvival: number;
    lowSurvival: number;
}

@observer
export class RelativeLinear extends React.Component<IRelativeLinearProps> {
    plot!: () => void;
    componentDidMount() {
        this.plot = drawRelativeLinear('#RelativeLinear', 800, 70, 0, 5);
        //@ts-ignore
        this.plot.UserScore({
            value: this.props.medianSurvival,
            high: this.props.highSurvival,
            low: this.props.lowSurvival,
        });
    }
    componentDidUpdate() {
        //@ts-ignore
        this.plot.UserScore({
            value: this.props.medianSurvival,
            high: this.props.highSurvival,
            low: this.props.lowSurvival,
        });
    }
    render() {
        return <div id="RelativeLinear" />;
    }
}

//@ts-ignore
function drawRelativeLinear(id, width, height, min, max) {
    // Define margins
    var margin = {
            top: 15,
            right: 150,
            bottom: 20,
            left: 50,
        },
        //@ts-ignore
        width = width - margin.left - margin.right,
        //@ts-ignore
        height = height - margin.top - margin.top;

    // initial userScore at midpoint and high and low at max and min;
    var userScore = min + (max - min) / 2,
        high = max,
        low = min,
        mid = low + (high - low) / 2,
        // create array for 5 evenly spaced tick marks on background array
        fifths = d3.range(min, max, (max - min) / 4);
    fifths.push(max);

    var tickCircleLocation = [min, mid, max];

    var format = d3.format('.1f');

    // Define scales and domain
    var xScale = d3
        .scaleLinear()
        .range([0, width])
        .domain([min, max])
        .clamp(true);

    var color = d3
        .scaleLinear()
        //@ts-ignore
        .range(['#008000', '#48D1CC', '#663399']) // green to purple
        .domain([min, mid, max])
        .clamp(true);
    // add .interpolate(interpolate..) if needed for non-continues obejcts.
    // Define svg and initialize visualization
    var svg = d3
        .select(id)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    //create filters for color gradient and drop shadow as a svg defs
    var defs = svg.append('defs');

    // ------ create filter with id #dropShadow ------ //
    // height=130% so that the shadow is not clipped
    var filter = defs
        .append('filter')
        .attr('id', 'dropShadow')
        .attr('height', '130%');

    // SourceAlpha refers to opacity of graphic that this filter will be applied to
    // convolve that with a Gaussian with standard deviation 3 and store result
    // in blur
    filter
        .append('feGaussianBlur')
        .attr('in', 'SourceAlpha')
        .attr('stdDeviation', 3)
        .attr('result', 'blur');

    // translate output of Gaussian blur to the right and downwards with X px
    // store result in offsetBlur
    filter
        .append('feOffset')
        .attr('in', 'blur')
        .attr('dx', 0)
        .attr('dy', 2)
        .attr('result', 'offsetBlur');

    // overlay original SourceGraphic over translated blurred opacity by using
    // feMerge filter. Order of specifying inputs is important!
    var feMerge = filter.append('feMerge');

    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    // ------ end drop shadow ------- //
    // attributes for color gradient. Todo: refactor using data() enter() if anymore stop-color positions
    //@ts-ignore
    function gradient(selection) {
        selection
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '100%')
            .attr('y2', '0%');

        //Set the color for the start (0%)
        selection
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', function() {
                return color(low);
            });

        selection
            .append('stop')
            .attr('offset', '50%')
            .attr('stop-color', function() {
                return color(mid);
            });

        //Set the color for the end (100%)
        selection
            .append('stop')
            .attr('offset', '100%')
            .attr('stop-color', function() {
                return color(high);
            });
    }

    // create linear gradient for highLow with id #resultGradient
    //@ts-ignore
    var resultGradient = defs
        .append('linearGradient')
        .attr('id', 'resultGradient')
        .call(gradient);

    // create linear gradient for background
    //@ts-ignore
    var backgroundGradient = defs
        .append('linearGradient')
        .attr('id', 'backgroundGradient')
        .call(gradient);

    // background - note the opacity
    var background = svg.append('g');

    background
        .append('g')
        .append('rect')
        .attr('x', xScale(low))
        .attr('y', 12)
        .attr('width', xScale(high) - xScale(low))
        .attr('height', 24)
        .style('fill', 'url(#backgroundGradient)')
        .attr('opacity', 0.6)
        .attr('id', 'background');

    //x-axis
    background
        .append('g')
        .attr('id', 'tickLine')
        .attr('transform', 'translate(0,' + 12 + ')')
        .call(
            //@ts-ignore
            d3
                .axisTop(xScale)
                .tickValues(fifths)
                .tickSize(-24),
        );

    background
        .append('g')
        .selectAll('circle')
        .data(tickCircleLocation)
        .enter()
        .append('circle')
        .attr('r', 15)
        .attr('cx', function(d) {
            return xScale(d);
        })
        .attr('cy', 24)
        .attr('class', 'tickCircle')
        .attr('stroke', function(d) {
            return color(d);
        })
        .attr('stroke-width', 4)
        .attr('stroke-opacity', 0.6)
        .attr('fill', 'white');

    background
        .append('g')
        .selectAll('text')
        .data(tickCircleLocation)
        .enter()
        .append('text')
        .attr('x', function(d) {
            return xScale(d);
        })
        .attr('y', 30)
        .text(function(d, i) {
            return i === 0 ? d : format(d);
        })
        .style('font', '16px sans-serif ')
        .attr('text-anchor', 'middle')
        .attr('class', 'tickCirlceText');

    // userScore, userValue and highLow bar
    // initalize by placing circle at centre of plot
    var result = svg.append('g').style('filter', 'url(#dropShadow)');

    result
        .append('rect')
        .attr('x', xScale(low))
        .attr('y', 9)
        .attr('width', xScale(high) - xScale(low))
        .attr('height', 30)
        .style('fill', 'url(#linearGradient)')
        .attr('opacity', 1)
        .attr('id', 'highLow');

    result
        .append('circle')
        .attr('r', 30)
        .attr('cx', xScale(userScore))
        .attr('cy', 24)
        .attr('id', 'userScore')
        .attr('stroke', function() {
            return color(userScore);
        })
        .attr('stroke-width', 6)
        .attr('fill', 'white');

    result
        .append('text')
        .attr('x', xScale(userScore))
        .attr('y', 34)
        .text(userScore)
        .style('font', '26px sans-serif')
        .attr('text-anchor', 'middle')
        .raise()
        .attr('id', 'userValue');

    function updatePlot() {}

    //@ts-ignore
    updatePlot.UserScore = function(_) {
        if (!arguments.length) return;
        userScore = _.value;
        high = _.high > max ? max : _.high;
        low = _.low < min ? min : _.low;
        mid = low + (high - low) / 2;

        // Just to be safe... ensure the correct layering of objects.
        //d3.select("#tickLine").raise();
        d3.select('#highLow').raise();
        d3.select('#userScore').raise();
        d3.select('#userValue').raise();

        //Change color at stop offsets
        d3.select('defs stop:nth-child(1)').attr('stop-color', function() {
            return color(low);
        });

        d3.select('defs stop:nth-child(2)').attr('stop-color', function() {
            return color(mid);
        });

        d3.select('defs stop:nth-child(3)').attr('stop-color', function() {
            return color(high);
        });

        //TODO: tween the gradient
        d3.select('#highLow')
            .transition()
            .duration(2000)
            .ease(d3.easeCubic)
            .attr('x', xScale(low))
            .attr('width', xScale(high) - xScale(low))
            .style('fill', 'url(#resultGradient)');

        d3.select('#userScore')
            .transition()
            .duration(2000)
            .ease(d3.easeCubic)
            .attr('cx', xScale(userScore))
            .attr('stroke', function() {
                return color(userScore);
            });

        d3.select('#userValue')
            .transition()
            .ease(d3.easeCubic)
            .duration(2000)
            .on('start', function change() {
                //@ts-ignore
                d3.active(this).tween('text', function() {
                    var from = d3.select(this),
                        i = d3.interpolateNumber(
                            //@ts-ignore
                            from.text().replace(/,/g, ''),
                            userScore,
                        );
                    return function(t) {
                        from.text(format(i(t)));
                    };
                });
            })
            .attr('x', xScale(userScore));

        return updatePlot;
    };

    return updatePlot;
} // end
