import * as React from 'react';
import { observer } from 'mobx-react';
import * as d3 from 'd3';

export interface IBeeSwarmPlotProps {
    data: Array<{
        percentile: number;
        time: number;
    }>;
    selectedGroup: number;
    selectedSurvivalTime: number;
}

@observer
export class BeeSwarmPlot extends React.Component<IBeeSwarmPlotProps> {
    beeSwarmPlot!: () => any;
    componentDidMount() {
        //@ts-ignore
        this.beeSwarmPlot = drawBeeSwarmPlot({
            data: this.props.data.filter(function(d) {
                return d.percentile < 100 && d.time < 1800;
            }),
            selectedGroup: this.props.selectedGroup,
            selectedSurvivalTime: this.props.selectedSurvivalTime,
        });
        d3.select('#BeeSwarmPlot').call(this.beeSwarmPlot);
        //@ts-ignore
        this.beeSwarmPlot.HightlightSurival(this.props.selectedSurvivalTime);
    }
    componentDidUpdate() {
        this.beeSwarmPlot
            //@ts-ignore
            .Data(
                this.props.data.filter(function(d) {
                    return d.percentile < 100 && d.time < 1800;
                }),
            )
            .HightlightSurival(this.props.selectedSurvivalTime);
    }
    render() {
        return <div id="BeeSwarmPlot" />;
    }
}

function drawBeeSwarmPlot({ data, selectedSurvivalTime }: IBeeSwarmPlotProps) {
    var width = 800,
        height = 220,
        margin = {
            top: 0,
            right: 180,
            bottom: 0,
            left: 50,
        };

    //@ts-ignore
    var updateData, updateHighlightSurival;

    // var data = [], config = [];
    // variables that are used in this function, but may not be apparent:

    //@ts-ignore
    function chart(selection) {
        selection.each(function() {
            var BeeSwarmPlot = d3.select('#BeeSwarmPlot');

            (width = width - margin.left - margin.right),
                (height = height - margin.top - margin.bottom);
            var g = BeeSwarmPlot.append('svg')
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', 'translate(' + margin.left + ',' + 0 + ')');

            // ------- https://bl.ocks.org/mbostock/6526445e2b44303eebf21da3b6627320
            var x = d3
                .scaleLinear()
                .domain([0, 1800])
                .rangeRound([0, width]);

            var simulation = d3
                //@ts-ignore
                .forceSimulation(data)
                .force(
                    'x',
                    d3
                        .forceX(function(d) {
                            //@ts-ignore
                            return x(d.time);
                        })
                        .strength(1),
                )
                .force('y', d3.forceY(height / 2))
                .force('collide', d3.forceCollide(7))
                .stop();

            for (var i = 0; i < 120; ++i) simulation.tick();

            var cell = g
                .append('g')
                .attr('class', 'cells')
                .selectAll('g')
                .data(
                    d3
                        .voronoi()
                        .extent([
                            [-margin.left, -margin.top],
                            [width + margin.right, height + margin.top],
                        ])
                        .x(function(d) {
                            //@ts-ignore
                            return d.x;
                        })
                        .y(function(d) {
                            //@ts-ignore
                            return d.y;
                        })
                        //@ts-ignore
                        .polygons(data),
                )
                .enter()
                .append('g');

            cell
                .append('circle')
                .attr('r', 6)
                .attr('cx', function(d) {
                    //@ts-ignore
                    return d.data.x;
                })
                .attr('cy', function(d) {
                    //@ts-ignore
                    return d.data.y;
                })
                .attr('class', 'beeCircles')
                .attr('fill', 'orange');

            cell.append('path').attr('d', function(d) {
                //@ts-ignore
                return 'M' + d.join('L') + 'Z';
            });

            cell.append('title').text(function(d) {
                //@ts-ignore
                return d.data.percentile + '\n' + d.data.time;
            });

            updateData = function() {
                d3.select('.cells').remove();

                var simulation = d3
                    //@ts-ignore
                    .forceSimulation(data)
                    .force(
                        'x',
                        d3
                            .forceX(function(d) {
                                //@ts-ignore
                                return x(d.time);
                            })
                            .strength(1),
                    )
                    .force('y', d3.forceY(height / 2))
                    .force('collide', d3.forceCollide(7))
                    .stop();

                for (var i = 0; i < 120; ++i) simulation.tick();

                cell = g
                    .append('g')
                    .attr('class', 'cells')
                    .selectAll('g')
                    .data(
                        d3
                            .voronoi()
                            .extent([
                                [-margin.left, -margin.top],
                                [width + margin.right, height + margin.top],
                            ])
                            .x(function(d) {
                                //@ts-ignore
                                return d.x;
                            })
                            .y(function(d) {
                                //@ts-ignore
                                return d.y;
                            })
                            //@ts-ignore
                            .polygons(data)
                            //@ts-ignore
                            .filter(datum => {
                                return datum !== undefined;
                            }),
                    )
                    .enter()
                    .append('g');

                cell
                    .append('circle')
                    .attr('class', 'beeCircles')
                    .attr('r', 6)
                    .attr('cx', function(d) {
                        //@ts-ignore
                        return d.data.x;
                    })
                    .attr('cy', function(d) {
                        //@ts-ignore
                        return d.data.y;
                    })
                    .attr('fill', 'orange');

                cell.append('path').attr('d', function(d) {
                    //@ts-ignore
                    return 'M' + d.join('L') + 'Z';
                });

                cell.append('title').text(function(d) {
                    //@ts-ignore
                    return d.data.percentile + '\n' + d.data.time;
                });
            };

            updateHighlightSurival = function() {
                d3
                    .select('.cells')
                    .selectAll('circle')
                    //@ts-ignore
                    .attr('class', function(d) {
                        //@ts-ignore
                        if (d.data.x <= x(selectedSurvivalTime)) {
                            return 'selectedSurvivalTime';
                        }
                    });
            };
        });
    }

    //@ts-ignore
    chart.Data = function(value) {
        if (!arguments.length) return data;
        data = value;
        //@ts-ignore
        if (typeof updateData === 'function') updateData();
        return chart;
    };

    //@ts-ignore
    chart.HightlightSurival = function(value) {
        if (!arguments.length) return selectedSurvivalTime;
        selectedSurvivalTime = value;
        //@ts-ignore
        if (typeof updateHighlightSurival === 'function')
            //@ts-ignore
            updateHighlightSurival();
        return chart;
    };

    return chart;
}
// end beeSwarmPlot
