import { observable } from 'mobx';

export class Switch {
    @observable
    isChecked: boolean = false;

    toggleSwitch = () => {
        this.isChecked = !this.isChecked;
    };
}
