import './algorithm-page-controls.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { getLocalizedStringForId } from '../../../strings/strings';
import { SwitchControl } from '../SwitchControl/SwitchControl';
import { SliderControl } from '../SliderControl/SliderControl';
import { RangeSliderControl } from '../RangeSliderControl/RangeSliderControl';
import { Card } from '@blueprintjs/core';

export interface IAlgorithmPageControlsProps {
    currentAge: number;
    onAgeSliderChange: (newAge: number) => void;
    currentExposureFrom: number;
    currentExposureTo: number;
    onExposureSliderChange: (newValues: [number, number]) => void;
    isGenderSwitchChecked: boolean;
    onGenderSwitchToggle: () => void;
    isPrevalenceSwitchChecked: boolean;
    onPrevalenceSwitchToggle: () => void;
    showPrevalence?: boolean;
}

export const AlgorithmPageControls: React.SFC<
    IAlgorithmPageControlsProps
> = observer(
    ({
        onAgeSliderChange,
        currentAge,
        currentExposureFrom,
        currentExposureTo,
        onExposureSliderChange,
        isGenderSwitchChecked,
        onGenderSwitchToggle,
        isPrevalenceSwitchChecked,
        onPrevalenceSwitchToggle,
        showPrevalence,
    }: IAlgorithmPageControlsProps) => {
        return (
            <div className="algorithm-page-controls">
                <Card>
                    <SliderControl
                        label={getLocalizedStringForId('age_slider_title')}
                        className="algorithm-page-controls__age-slider"
                        sliderProps={{
                            max: 80,
                            min: 20,
                            labelRenderer: value => {
                                return (
                                    <span>
                                        {value}
                                        <br />
                                        years
                                    </span>
                                );
                            },
                            labelStepSize: 60,
                            onChange: newSliderValue => {
                                return onAgeSliderChange(
                                    Number(newSliderValue),
                                );
                            },
                            onRelease: newSliderValue => {
                                return onAgeSliderChange(
                                    Number(newSliderValue),
                                );
                            },
                            value: currentAge,
                        }}
                    />
                    <RangeSliderControl
                        className="algorithm-page-controls__exposure-slider"
                        rangeSliderProps={{
                            max: 90,
                            min: 10,
                            labelStepSize: 80,
                            labelRenderer: value => {
                                return `${value} %ile`;
                            },
                            value: [currentExposureFrom, currentExposureTo],
                            onChange: (newSliderValue: any) => {
                                return onExposureSliderChange(newSliderValue);
                            },
                            onRelease: (newSliderValue: any) => {
                                return onExposureSliderChange(newSliderValue);
                            },
                        }}
                        label={getLocalizedStringForId('exposure_slider_title')}
                    />
                    <SwitchControl
                        className="algorithm-page-controls__gender-switch-control"
                        switchProps={{
                            checked: isGenderSwitchChecked,
                            onChange: onGenderSwitchToggle,
                        }}
                        label={getLocalizedStringForId('gender_toggle')}
                        leftLabel={getLocalizedStringForId('male_toggle')}
                        rightLabel={getLocalizedStringForId('female_toggle')}
                    />
                    {showPrevalence ? (
                        <SwitchControl
                            className="algorithm-page-controls__prevalence-switch-control"
                            switchProps={{
                                checked: isPrevalenceSwitchChecked,
                                onChange: onPrevalenceSwitchToggle,
                            }}
                            label={getLocalizedStringForId('prevalence_toggle')}
                            leftLabel={getLocalizedStringForId('hide_preva')}
                            rightLabel={getLocalizedStringForId('show_preva')}
                        />
                    ) : (
                        undefined
                    )}
                </Card>
            </div>
        );
    },
);
