import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { SurvivalPlot as DumbSurvivalPlot } from '../dumb/SurvivalPlot/SurvivalPlot';
import { Respect } from '../../store/ui/respect/respect';
import { round } from 'lodash';

export interface ISurvivalPlotProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class SurvivalPlot extends React.Component<ISurvivalPlotProps> {
    render() {
        const {
            survivalPlotData,
            groupSlider,
            medianSurvivalForSelectedBin,
            setSurvivalTime,
            survivalTime,
        } = this.props.respect!;

        return (
            <DumbSurvivalPlot
                data={survivalPlotData}
                selectedGroup={groupSlider.currentValue}
                selectedSurvivalTime={round(survivalTime / 365, 1)}
                medianSurvival={round(medianSurvivalForSelectedBin / 365, 1)}
                updateSurvivalTime={survivalTime => {
                    return setSurvivalTime(round(survivalTime * 365));
                }}
            />
        );
    }
}
