import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { AlgorithmPageControls } from '../dumb/AlgorithmPageControls/AlgorithmPageControls';
import { MPoRTPage } from '../../store/ui/mport-page';

export interface MPoRTAlgorithmPageControlsProps {
    mport?: MPoRTPage;
}

@inject('mport')
@observer
export class MPoRTAlgorithmPageControls extends React.Component<
    MPoRTAlgorithmPageControlsProps
> {
    render() {
        const controls = this.props.mport!.controls;

        return (
            <AlgorithmPageControls
                currentAge={controls.ageSlider.currentValue}
                onAgeSliderChange={controls.ageSlider.setCurrentValue}
                currentExposureFrom={controls.exposureSlider.fromValue}
                currentExposureTo={controls.exposureSlider.toValue}
                onExposureSliderChange={controls.exposureSlider.updateValues}
                isGenderSwitchChecked={controls.sexSwitch.isChecked}
                onGenderSwitchToggle={controls.sexSwitch.toggleSwitch}
                isPrevalenceSwitchChecked={false}
                onPrevalenceSwitchToggle={() => {}}
            />
        );
    }
}
