import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Respect } from '../../store/ui/respect/respect';
import { ReactLinear as DumbRectLinear } from '../dumb/RectLinear/RectLinear';
import { CoxSurvivalAlgorithm } from '@ottawamhealth/pbl-calculator-engine/lib/engine/algorithm/regression-algorithm/cox-survival-algorithm/cox-survival-algorithm';

export interface IRectLinearProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class ReactLinear extends React.Component<IRectLinearProps> {
    render() {
        const { groupSlider, modelsSingleton } = this.props.respect!;

        return (
            <DumbRectLinear
                selectedGroup={groupSlider.currentValue}
                numberOfBins={
                    (modelsSingleton.respect as CoxSurvivalAlgorithm).bins!
                        .binsLookup.length
                }
            />
        );
    }
}
