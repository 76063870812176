import * as React from 'react';
import { RelativeRiskGraph } from '../dumb/RelativeRiskGraph/RelativeRiskGraph';
import { observer, inject } from 'mobx-react';
import { MPoRTPage } from '../../store/ui/mport-page';
import { Gender } from '../../store/gender';
import { InteractionCovariate } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/covariate/interaction-covariate/interaction-covariate';
import { computed } from 'mobx';
import { getLocalizedStringForId } from '../../strings/strings';
import {
    ContinuousVisualConfigPredictorObjJson,
    CategoricalVisualConfigPredictorObjJson,
} from '../../store/domain/visual-config';
import { Covariate } from '@ottawamhealth/pbl-calculator-engine/lib/engine/data-field/covariate/covariate';
import { sortBy } from 'lodash';

export interface MPoRTRelativeRiskGraphProps {
    mport?: MPoRTPage;
}

@inject('mport')
@observer
export class MPoRTRelativeRiskGraph extends React.Component<
    MPoRTRelativeRiskGraphProps
> {
    render() {
        const controls = this.props.mport!.controls;
        const exposureSlider = controls.exposureSlider;

        return (
            <div className="mport-rel-risk-graph">
                <RelativeRiskGraph
                    plotData={this.plotData}
                    age={controls.ageSlider.currentValue}
                    exposureTo={exposureSlider.toValue}
                    showPrevalence={false}
                    minRelRisk={0.11}
                    maxRelRisk={
                        controls.sexSwitch.getCurrentGender() === Gender.Male
                            ? 11
                            : 18
                    }
                    onPredictorCircleClick={predictor => {
                        this.props.mport!.setCurrentPredictor(predictor);
                    }}
                    xAxisLabel={'5 Year Risk of Mortality'}
                />
            </div>
        );
    }
    @computed
    private get plotData() {
        const mportPage = this.props.mport!;

        const mportSingleton = MPoRTPage.mportSingleton;
        const controls = mportPage.controls;
        const currentSex =
            controls.sexSwitch.getCurrentGender() === Gender.Male
                ? 'male'
                : 'female';
        const exposureSlider = controls.exposureSlider;

        const plotData = sortBy(
            this.covariatesForGraph
                .map(covariate => {
                    const relRisk = mportSingleton.getRelativeRiskForPredictor(
                        covariate.name,
                        currentSex,
                        controls.ageSlider.currentValue,
                        exposureSlider.toValue,
                        exposureSlider.fromValue,
                    );
                    const generalSpecForCovariate = mportSingleton.findGeneralSpecForPredictor(
                        covariate.name,
                    );
                    const visualConfigForCovariate = mportSingleton.visualConfig.getVisualConfigJsonObjForPredictor(
                        covariate.name,
                        currentSex,
                    );
                    const formattedRelRisk = this.formatNumber(relRisk);
                    const exposurePredictor = visualConfigForCovariate.percentilePredictors
                        ? visualConfigForCovariate.percentilePredictors[0]
                        : undefined;

                    return {
                        order: visualConfigForCovariate.order,
                        yAxisLabel: generalSpecForCovariate.displayName,
                        relRiskText: getLocalizedStringForId(
                            'circle_rel_risk_value',
                            [formattedRelRisk],
                        ),
                        beforeVersusText: this.formatRelativeRiskGraphText(
                            visualConfigForCovariate.relativeRiskHoverText,
                            covariate.name,
                            exposurePredictor,
                            formattedRelRisk,
                        ),
                        afterVersusText: this.formatRelativeRiskGraphText(
                            generalSpecForCovariate.variableType ===
                            'continuous'
                                ? (visualConfigForCovariate as ContinuousVisualConfigPredictorObjJson)
                                      .referenceText
                                : mportSingleton.findGeneralSpecForPredictor(
                                      (visualConfigForCovariate as CategoricalVisualConfigPredictorObjJson)
                                          .reference,
                                  ).displayName,
                            covariate.name,
                            exposurePredictor,
                            formattedRelRisk,
                        ),
                        referenceText: '',
                        relRisk,
                        exposurePrevalence: 40,
                        predictorName: covariate.name,
                    };
                })
                .concat(this.referencePlotData),
            'order',
        );
        plotData.reverse();

        return plotData;
    }
    private formatRelativeRiskGraphText(
        text: string,
        predictor: string,
        exposurePredictor: string | undefined,
        formattedRelRisk: string,
    ): string {
        const controls = this.props.mport!.controls;
        const exposureSlider = controls.exposureSlider;
        const currentSex = controls.sexSwitch.getCurrentGenderForEngine();
        const expTo = exposureSlider.toValue;
        const expFrom = exposureSlider.fromValue;

        const textVariables = {
            age: controls.ageSlider.currentValue,
            sex: currentSex,
            expTo,
            expFrom,
            expFromValue: this.formatNumber(
                MPoRTPage.mportSingleton.tableOne.getPredictorValue(
                    predictor,
                    [
                        {
                            name: 'RiskPercentile',
                            value: `${expFrom}`,
                        },
                        {
                            name: exposurePredictor ? exposurePredictor : 'NA',
                            value: 'NA',
                        },
                    ],
                    currentSex,
                ),
            ),
            expToValue: this.formatNumber(
                MPoRTPage.mportSingleton.tableOne.getPredictorValue(
                    predictor,
                    [
                        {
                            name: 'RiskPercentile',
                            value: `${expTo}`,
                        },
                        {
                            name: exposurePredictor ? exposurePredictor : 'NA',
                            value: 'NA',
                        },
                    ],
                    currentSex,
                ),
            ),
            relRisk: formattedRelRisk,
        };

        return Object.keys(textVariables).reduce(
            (formattedText, textVariable) => {
                return formattedText.replace(
                    `{{${textVariable}}}`,
                    (textVariables as any)[textVariable],
                );
            },
            text,
        );
    }
    private formatNumber(numToFormat: number): string {
        return numToFormat.toFixed(2);
    }
    @computed
    private get referencePlotData() {
        const currentSex = this.props.mport!.controls.sexSwitch.getCurrentGenderForEngine();
        const visualConfig = MPoRTPage.mportSingleton.visualConfig;

        return MPoRTPage.mportSingleton.visualConfig
            .getReferencePredictorsForSex(currentSex)
            .filter(reference => {
                return (
                    visualConfig.getCategoriesForReference(
                        reference,
                        currentSex,
                    ).length > 1
                );
            })
            .map(referencePredictor => {
                const generalSpec = MPoRTPage.mportSingleton.findGeneralSpecForPredictor(
                    referencePredictor,
                );
                return {
                    order:
                        visualConfig.getBiggestOrderForReference(
                            referencePredictor,
                            currentSex,
                        ) + 1,
                    yAxisLabel: generalSpec.displayName,
                    relRiskText: '',
                    beforeVersusText: '',
                    afterVersusText: '',
                    referenceText: generalSpec.displayName,
                    relRisk: 1,
                    exposurePrevalence: 40,
                    predictorName: referencePredictor,
                };
            });
    }
    @computed
    private get covariatesForGraph(): Covariate[] {
        return MPoRTPage.mportSingleton.model
            .getAlgorithmForData([
                {
                    name: 'sex',
                    coefficent: this.props.mport!.controls.sexSwitch.getCurrentGenderForEngine(),
                },
            ])
            .covariates.filter(covariate => {
                return covariate.groups.length > 1
                    ? true
                    : covariate.groups[0] === 'AGE'
                        ? false
                        : true;
            })
            .filter(covariate => {
                return covariate.customFunction
                    ? covariate.customFunction.variableNumber > 1
                        ? false
                        : true
                    : true;
            })
            .filter(covariate => {
                return (
                    covariate.name !== 'PackYearsC_rcs1' &&
                    covariate.name !== 'PackYearsC_rcs2'
                );
            })
            .filter(covariate => {
                return covariate instanceof InteractionCovariate === false;
            });
    }
}
