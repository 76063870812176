import * as React from 'react';
import { Respect as DumbRespect } from '../dumb/Respect/Respect';
import { Respect as RespectStore } from '../../store/ui/respect/respect';
import { observer, inject } from 'mobx-react';

export interface IRespectProps {
    respect?: RespectStore;
}

@inject('respect')
@observer
export class Respect extends React.Component<IRespectProps> {
    render() {
        const { activeSecondaryVisualizationNames } = this.props.respect!;

        return (
            <DumbRespect
                activeSecondaryVisualizations={
                    activeSecondaryVisualizationNames
                }
            />
        );
    }
}
