import { Slider } from './slider';
import { RangeSlider } from './range-slider';
import { GenderSwitch } from './gender-switch';
import { PrevalenceSwitch } from './prevalence-switch';
import { autobind } from 'core-decorators';

@autobind
export class PortPageControls {
    ageSlider: Slider;
    exposureSlider: RangeSlider;
    sexSwitch: GenderSwitch;
    prevalenceSwitch: PrevalenceSwitch;

    constructor() {
        this.ageSlider = new Slider(30);
        this.exposureSlider = new RangeSlider(10, 90);
        this.sexSwitch = new GenderSwitch();
        this.prevalenceSwitch = new PrevalenceSwitch();
    }
}
