import * as strings from './en';

export function getLocalizedStringForId(
    key: keyof typeof strings,
    parameters?: (string | number)[],
): string {
    if (!parameters) {
        return strings[key];
    } else {
        return parameters.reduce(
            (currentString: string, currentParameter, index) => {
                return currentString.replace(
                    `{{${index + 1}}}`,
                    currentParameter + '',
                );
            },
            strings[key],
        );
    }
}
