import { GroupSlider } from './group-slider';
import { observable } from 'mobx';
import {
    modelsSingleton,
    ModelsSingleton,
} from '../../domain/models-singleton';
import { CoxSurvivalAlgorithm } from '@ottawamhealth/pbl-calculator-engine/lib/engine/algorithm/regression-algorithm/cox-survival-algorithm/cox-survival-algorithm';
import { Data } from '../../../components/dumb/SurvivalPlot/SurvivalPlot';
import { IBeeSwarmPlotProps } from '../../../components/dumb/BeeSwarmPlot/BeeSwarmPlot';
import { IBinData } from '@ottawamhealth/pbl-calculator-engine/lib/engine/algorithm/regression-algorithm/cox-survival-algorithm/bins/bins';
import { autobind } from 'core-decorators';
import { sortedIndexBy, round, sortBy } from 'lodash';
import {
    Visualizations,
    getVisualizationNameFromVisualization,
} from './visualizations';
import { IWebSpecRow } from '../../../models/labels';
import {
    ICategoricalTableOne,
    IContinuousTableOne,
} from '../../../models/tabel-1';

@autobind
export class Respect {
    @observable groupSlider: GroupSlider;
    @observable modelsSingleton: ModelsSingleton = modelsSingleton;
    //In days
    @observable survivalTime: number;
    @observable activeSecondaryVisualizations: Visualizations[];

    constructor() {
        this.groupSlider = new GroupSlider(25);
        this.survivalTime = 365;

        this.activeSecondaryVisualizations = [];
    }

    toggleSecondaryVisualization(visualization: Visualizations) {
        const activeIndex = this.activeSecondaryVisualizations.findIndex(
            activeSecondaryVisualization => {
                return activeSecondaryVisualization === visualization;
            },
        );

        if (activeIndex === -1) {
            this.activeSecondaryVisualizations.push(visualization);
        } else {
            this.activeSecondaryVisualizations = this.activeSecondaryVisualizations.filter(
                (activeSecondaryVisualization, index) => {
                    activeSecondaryVisualization;
                    return index !== activeIndex;
                },
            );
        }
    }

    get activeSecondaryVisualizationNames(): Array<
        ReturnType<typeof getVisualizationNameFromVisualization>
    > {
        return this.activeSecondaryVisualizations.map(visualization => {
            return getVisualizationNameFromVisualization(visualization);
        });
    }

    setSurvivalTime(survivalTime: number) {
        this.survivalTime = survivalTime;
    }

    get isLoading(): boolean {
        return this.modelsSingleton.respect === false ? true : false;
    }

    get currentBin(): IBinData[] {
        const numOfBins = (modelsSingleton.respect as CoxSurvivalAlgorithm)
            .bins!.binsLookup.length;

        return (modelsSingleton.respect as CoxSurvivalAlgorithm).bins!.binsData[
            numOfBins - this.groupSlider.currentValue + 1
        ];
    }

    get survivalPlotData(): Data {
        const binsData = (modelsSingleton.respect as CoxSurvivalAlgorithm).bins!
            .binsData;
        const binNumbers = Object.keys(binsData);

        return binNumbers
            .slice()
            .reverse()
            .map((binNumber, index) => {
                return {
                    // Do this since in the model the groups go from high risk to low risk where as in the grpah we want to go in the reverse direction
                    key: `${index + 1}`,
                    values: binsData[Number(binNumber)]
                        .filter(binDatum => {
                            return binDatum.time ? binDatum.time < 1800 : true;
                        })
                        .map(binDatum => {
                            return {
                                percentile: binDatum.survivalPercent,
                                //Convert time to years
                                time: binDatum.time
                                    ? binDatum.time / 365
                                    : null,
                            };
                        }),
                };
            });
    }

    get medianSurvivalForSelectedBin(): number {
        return this.currentBin.find(binDatum => {
            return binDatum.survivalPercent === 50;
        })!.time as number;
    }

    get beeSwarmPlotData(): IBeeSwarmPlotProps['data'] {
        return this.currentBin
            .map(binDatum => {
                return {
                    percentile: binDatum.survivalPercent,
                    time: binDatum.time,
                };
            })
            .filter(beeSwarmPlotDatum => {
                return beeSwarmPlotDatum.time !== undefined;
            }) as IBeeSwarmPlotProps['data'];
    }

    get iconGraphSurvivalPercent(): number {
        const closestBinDatumIndex = sortedIndexBy(
            this.currentBin.filter(binDatum => {
                return binDatum.time !== undefined;
            }),
            {
                time: this.survivalTime,
                survivalPercent: 0,
            },
            function(binDatum) {
                return binDatum.time;
            },
        );
        return 100 - this.currentBin[closestBinDatumIndex].survivalPercent;
    }

    get stripePlotData(): Array<{
        percentile: number;
        time: number;
    }> {
        return this.beeSwarmPlotData.map(beeSwarmDatum => {
            return {
                ...beeSwarmDatum,
                time: round(beeSwarmDatum.time / 365, 1),
            };
        });
    }

    getSurvivalTimeFromPercent(percent: number): number {
        const time = this.currentBin.find(binDatum => {
            return binDatum.survivalPercent === percent;
        })!.time;

        return time ? time : 20000;
    }

    get reversedCurrentBinNumber(): number {
        return this.groupSlider.currentValue ===
            (this.modelsSingleton.respect as CoxSurvivalAlgorithm).bins!
                .binsLookup.length
            ? 1
            : this.groupSlider.currentValue === 1
                ? 61
                : 61 - this.groupSlider.currentValue;
    }

    get tableInfoForCurrentBin(): Array<{
        type: string;
        predictors: Array<
            | {
                  label: string;
                  categories: Array<{
                      label: string;
                      percent: number;
                      isReference: boolean;
                  }>;
              }
            | {
                  label: string;
                  mean: number;
                  sd: number;
                  units: string;
              }
        >;
    }> {
        const WebSpec = this.modelsSingleton.respectWebSpec;
        const TableOneRowForCurrentBin = this.modelsSingleton.respectTable1[
            this.reversedCurrentBinNumber
        ];
        const Categories = this.modelsSingleton.respectCategories;

        //Get the array of variable types (Demographics etc.) ordered by the type_Order variable
        const orderedTypes = sortBy(this.modelsSingleton.respectWebSpec, [
            (webSpecRow: IWebSpecRow) => {
                return webSpecRow.type_Order;
            },
        ])
            .map(webSpecRow => {
                return webSpecRow.Type;
            })
            .reduce((uniqueTypes: string[], currentType) => {
                return uniqueTypes.indexOf(currentType) > -1
                    ? uniqueTypes
                    : uniqueTypes.concat(currentType);
            }, []);

        return orderedTypes.map(currentType => {
            const webSpecRowsForCurrentType = WebSpec.filter(webSpecRow => {
                return webSpecRow.Type === currentType;
            });

            return {
                type: currentType,
                predictors: webSpecRowsForCurrentType.map(webSpecRow => {
                    if (webSpecRow.variableType === 'Categorical') {
                        return {
                            label: webSpecRow.Label,
                            categories: Categories.filter(category => {
                                return (
                                    category.Variable_Name ===
                                    webSpecRow.Variable_Name
                                );
                            })
                                .filter(category => {
                                    if (
                                        category.Variable_Name.indexOf(
                                            '_cat2',
                                        ) > -1
                                    ) {
                                        if (category.Reference === 'Yes') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        return true;
                                    }
                                })
                                .map(category => {
                                    return {
                                        label: category.Label,
                                        isReference:
                                            category.Reference === 'Yes',
                                        percent: (TableOneRowForCurrentBin[
                                            category.Dummy
                                        ] as ICategoricalTableOne).percent,
                                    };
                                }),
                        };
                    } else {
                        const tableOneRow =
                            TableOneRowForCurrentBin[webSpecRow.Variable_Name];

                        return {
                            label: webSpecRow.Label,
                            mean: (tableOneRow as IContinuousTableOne).mean,
                            sd: (tableOneRow as IContinuousTableOne).sd,
                            units: webSpecRow.Units,
                        };
                    }
                }),
            };
        });
    }

    get numOfPeopleInCurrentBin(): number {
        return this.modelsSingleton.respectTable1[
            String(this.groupSlider.currentValue)
        ]['n'] as number;
    }

    get currentBinPercentileRanges(): [number, number] {
        const currentBinNumber = this.reversedCurrentBinNumber;

        let lowerPercentile = 0;
        for (
            let i = (this.modelsSingleton.respect as CoxSurvivalAlgorithm).bins!
                .binsLookup.length;
            i > currentBinNumber;
            i--
        ) {
            lowerPercentile += this.modelsSingleton.respectBinPercentiles[i - 1]
                .Percent;
        }

        return [
            round(lowerPercentile, 1),
            round(
                lowerPercentile +
                    this.modelsSingleton.respectBinPercentiles[
                        currentBinNumber - 1
                    ].Percent,
                1,
            ),
        ];
    }
}
