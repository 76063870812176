import * as React from 'react';
import { observer } from 'mobx-react';
import * as d3 from 'd3';

export interface IStripePlotProps {
    data: Array<{
        percentile: number;
        time: number;
    }>;
    medianSurvival: number;
}

@observer
export class StripePlot extends React.Component<IStripePlotProps> {
    stripePlot!: () => void;
    componentDidMount() {
        //@ts-ignore
        this.stripePlot = drawStripPlot(
            '#StripePlot',
            this.props.data,
            this.props.medianSurvival,
        );
        d3.select('#StripePlot').call(this.stripePlot);
    }
    componentDidUpdate() {
        this.stripePlot
            //@ts-ignore
            .Strips(this.props.data)
            .Median(this.props.medianSurvival);
    }
    render() {
        return <div id="StripePlot" />;
    }
}

// drawStripPlot.js
//@ts-ignore
function drawStripPlot(id, data: IStripePlotProps['data'], medianSurvival) {
    // data is same as drawBeeSwarmPlot
    var width = 800,
        height = 80,
        margin = {
            top: 10,
            right: 180,
            bottom: 25,
            left: 50,
        };

    var width = width - margin.left - margin.right,
        height = height - margin.top - margin.bottom;

    const largestTimeValue = 5;

    var x = d3
        .scaleLinear()
        .range([0, width])
        .domain([0, largestTimeValue])
        .clamp(true); // Do not allow domain values below 0 or above 1800. Used to restrict selectedSurvivalTime.

    var upperPercentile = 75,
        lowerPercentile = 25;

    // calculate the height of the strip. Make it triangle shape.
    //@ts-ignore
    function y1(d) {
        return 0 + 15 * (Math.abs(d - 50) / (50 - lowerPercentile));
    }
    //@ts-ignore
    function y2(d) {
        return 30 - 15 * (Math.abs(d - 50) / (50 - lowerPercentile));
    }

    //@ts-ignore
    var updateMedian, updateData;

    //@ts-ignore
    function chart(selection) {
        selection.each(function() {
            var plot = d3.select(id);

            var g = plot
                .append('svg')
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom)
                .append('g')
                .attr(
                    'transform',
                    'translate(' + margin.left + ',' + margin.top + ')',
                );

            //x-axis
            g.append('g')
                .attr('transform', 'translate(0,' + height + ')')
                //@ts-ignore
                .call(d3.axisBottom(x))
                .append('text')
                .attr('y', -2)
                .attr('x', width - 30)
                .text('Time (years)')
                .attr('class', 'axisLabel');

            g.selectAll('.stripLine')
                .data(data.filter(percentileRange)) // filter for high and low boundry to create confindence intervals.
                .enter()
                .append('line')
                .attr('class', 'stripLine')
                .attr('x1', function(d) {
                    //@ts-ignore
                    return x(d.time);
                })
                .attr('x2', function(d) {
                    //@ts-ignore
                    return x(d.time);
                })
                .attr('y1', function(d) {
                    //@ts-ignore
                    return y1(d.percentile);
                })
                .attr('y2', function(d) {
                    //@ts-ignore
                    return y2(d.percentile);
                });

            g.append('circle')
                //@ts-ignore
                .filter(function() {
                    if (medianSurvival < 1800 / 365) {
                        return true;
                    }
                })
                .attr('r', 15)
                .attr('cx', x(medianSurvival))
                .attr('cy', 15)
                .attr('id', 'medianSurvival');

            updateMedian = function() {
                d3.select('#medianSurvival')
                    .raise()
                    .transition()
                    .duration(1000)
                    .attr('cx', x(medianSurvival));
            };

            updateData = function() {
                var update = g
                    .selectAll('.stripLine')
                    .data(data.filter(percentileRange));

                update // update existing strips
                    .transition()
                    .duration(1000)
                    .attr('x1', function(d) {
                        //@ts-ignore
                        return x(d.time);
                    })
                    .attr('x2', function(d) {
                        //@ts-ignore
                        return x(d.time);
                    });

                update // add new strips, if needed
                    .enter()
                    .append('line')
                    .attr('class', 'stripLine')
                    .attr('x1', function(d) {
                        //@ts-ignore
                        return x(d.time);
                    })
                    .attr('x2', function(d) {
                        //@ts-ignore
                        return x(d.time);
                    })
                    .attr('y1', function(d) {
                        //@ts-ignore
                        return y1(d.percentile);
                    })
                    .attr('y2', function(d) {
                        //@ts-ignore
                        return y2(d.percentile);
                    })
                    .merge(update);

                update // remove strips, if needed
                    .exit()
                    .transition()
                    .duration(300)
                    .remove();
            };
        }); // end selection.each
    } // end chart selection

    //@ts-ignore
    chart.Median = function(value) {
        if (!arguments.length) return medianSurvival;
        medianSurvival = value;
        //@ts-ignore
        if (typeof updateMedian === 'function') updateMedian();
        return chart;
    };

    //@ts-ignore
    chart.Strips = function(value) {
        if (!arguments.length) return data;
        data = value;
        //@ts-ignore
        if (typeof updateData === 'function') updateData();
        return chart;
    };

    //@ts-ignore
    function percentileRange(d) {
        if (d.percentile < upperPercentile && d.percentile >= lowerPercentile) {
            return true;
        }
    }

    return chart;
} // end drawStripPlot
