import { maxBy } from 'lodash';

export class VisualConfig {
    visualConfigJson: VisualConfigJson;

    constructor(visualConfigJson: VisualConfigJson) {
        this.visualConfigJson = {
            '': [],
            male: visualConfigJson.male.concat(visualConfigJson['']),
            female: visualConfigJson.female.concat(visualConfigJson['']),
        };
    }

    getVisualConfigJsonObjForPredictor(
        predictor: string,
        sex: 'male' | 'female',
    ): VisualConfigPredictorObjJson {
        return this.findVisualConfigJsonObj(
            this.visualConfigJson[sex],
            predictor,
        )!;
    }

    getReferencePredictorsForSex(sex: 'male' | 'female'): string[] {
        return this.visualConfigJson[sex]
            .filter(visualConfigJsonObj => {
                return 'reference' in visualConfigJsonObj;
            })
            .map(visualConfigJsonObj => {
                return (visualConfigJsonObj as CategoricalVisualConfigPredictorObjJson)
                    .reference;
            });
    }

    getBiggestOrderForReference(
        reference: string,
        sex: 'male' | 'female',
    ): number {
        const categoriesForReference = this.getCategoriesForReference(
            reference,
            sex,
        );

        return (maxBy(categoriesForReference, visualConfigJsonObj => {
            return visualConfigJsonObj.order;
        }) as VisualConfigPredictorObjJson).order;
    }

    getCategoriesForReference(
        reference: string,
        sex: 'male' | 'female',
    ): VisualConfigPredictorObjJson[] {
        return this.visualConfigJson[sex].filter(visualConfigJsonObj => {
            return (
                'reference' in visualConfigJsonObj &&
                visualConfigJsonObj.reference === reference
            );
        });
    }

    private findVisualConfigJsonObj(
        visualConfigJsonObjs: VisualConfigPredictorObjJson[],
        predictor: string,
    ): VisualConfigPredictorObjJson | undefined {
        return visualConfigJsonObjs.find(visualConfigJsonObj => {
            return visualConfigJsonObj.predictor === predictor;
        });
    }
}

interface BaseVisualConfigPredictorObjJson {
    order: number;
    predictor: string;
    relativeRiskHoverText: string;
    percentilePredictors: string[];
}
export interface CategoricalVisualConfigPredictorObjJson
    extends BaseVisualConfigPredictorObjJson {
    reference: string;
}
export interface ContinuousVisualConfigPredictorObjJson
    extends BaseVisualConfigPredictorObjJson {
    relativeRiskHoverVersusText: string;
    referenceText: string;
}
type VisualConfigPredictorObjJson =
    | CategoricalVisualConfigPredictorObjJson
    | ContinuousVisualConfigPredictorObjJson;
export interface VisualConfigJson {
    '': VisualConfigPredictorObjJson[];
    male: VisualConfigPredictorObjJson[];
    female: VisualConfigPredictorObjJson[];
}
