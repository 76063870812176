import './respect-controls.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Slider } from '@blueprintjs/core';
import { Button } from '@blueprintjs/core';
import { ViewerLabel } from '../ViewerLabel/ViewerLabel';

export interface IRespectControlsProps {
    groupSliderMin: number;
    groupSliderMax: number;
    groupSliderValue: number;
    onGroupSliderChange: (newGroup: number) => void;
    toggleVisualization: (visualization: VisualizationTypes) => void;
    activeVisualizations: VisualizationTypes[];
}

export const RespectControls: React.SFC<IRespectControlsProps> = observer(
    ({
        groupSliderMax,
        groupSliderMin,
        groupSliderValue,
        onGroupSliderChange,
        toggleVisualization,
        activeVisualizations,
    }: IRespectControlsProps) => {
        return (
            <div>
                <Slider
                    className="respect-controls__group-slider"
                    max={groupSliderMax}
                    min={groupSliderMin}
                    labelStepSize={groupSliderMax - groupSliderMin}
                    onChange={onGroupSliderChange}
                    onRelease={onGroupSliderChange}
                    value={groupSliderValue}
                    labelRenderer={value => {
                        return `Group ${value}`;
                    }}
                />
                <ViewerLabel>Toggle Secondary Visualization</ViewerLabel>
                <div className="respect-controls__graph-icons-container">
                    {GraphsAndIcons.map(graphAndIcon => {
                        return (
                            <Button
                                key={graphAndIcon.name}
                                active={
                                    activeVisualizations.indexOf(
                                        graphAndIcon.name,
                                    ) > -1
                                }
                                className="respect-controls__graph-icon-container"
                                onClick={() => {
                                    return toggleVisualization(
                                        graphAndIcon.name,
                                    );
                                }}
                            >
                                <img
                                    src={`/dist/icons/${graphAndIcon.url}`}
                                    className={`respect-controls__graph-icon respect-controls__${
                                        graphAndIcon.className
                                    }`}
                                />
                            </Button>
                        );
                    })}
                </div>
            </div>
        );
    },
);

export type VisualizationTypes =
    | 'BeeSwarm'
    | 'IconGraph'
    | 'RectLinear'
    | 'StripePlot'
    | 'RelativeLinear';

const GraphsAndIcons: Array<{
    name: VisualizationTypes;
    url: string;
    className: string;
}> = [
    {
        name: 'BeeSwarm',
        url: 'bee-swarm.svg',
        className: 'bee-swarm-icon',
    },
    {
        name: 'IconGraph',
        url: 'icon-graph.svg',
        className: 'icon-graph-icon',
    },
    {
        name: 'RectLinear',
        url: 'rect-linear.svg',
        className: 'rect-linear-icon',
    },
    {
        name: 'StripePlot',
        url: 'stripe-plot.svg',
        className: 'stripe-plot-icon',
    },
    {
        name: 'RelativeLinear',
        url: 'relative-linear.svg',
        className: 'relative-linear-icon',
    },
];
