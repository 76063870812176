export const model_selector_title = 'Algorithm Viewer';
export const model_selector_dropdown = 'Select an algorithm to start';
export const modal_title = 'How the viewer works';
export const modal_description =
    'The circles show how much each risk factor increases or drecreases predicted risk. \n<br/><br/> \nThe <b>exposure</b> slider changes the exposure level for each risk, reflecting percentile exposure level in the <b>total population</b> (all ages). The <b>show/hide percentile</b> shows the percentile exposure as the circle size\n<br/><br/>\n<b>Hovering over</b> each circle shows details of the exposure and reference.\n<br/>\nPrevalence of exposure is shown when viewing percetile information.\n<br/><br/>\n<b>Clicking </b>each circle shows plots for the risk factor';
export const modal_continue_button = 'Continue';
export const model_title = 'Algorithm Viewer: CVDPoRT reduced model';
export const model_name = 'CVDPoRT reduced model';
export const exposure_slider_title = 'Exposure:';
export const age_slider_title = 'Age:';
export const age_slider = '{{1}}<br/>years';
export const exposure_slider = 'Exposure {{1}} %tile';
export const male_toggle = 'Male';
export const female_toggle = 'Female';
export const gender_toggle = 'Sex:';
export const prevalence_toggle = 'Prevalence:';
export const show_preva = 'Show prevalence';
export const hide_preva = 'Hide prevalence';
export const circle_rel_risk_value = '<b>{{1}} relative risk</b>';
export const circle_versus = '<b>versus</b>';
export const smoking_circle_rel_risk =
    '{{1}} year-old {{2}} who smokes {{3}} packyears ({{4}}%ile in the general population)';
export const smoking_circle_versus = '{{1}} never smoker';
export const smoking_ref = 'Reference for current or former smokers';
export const alcohol_circle_rel_risk =
    '{{1}} year-old {{2}} who drinks {{3}} drinks per week ({{4}}%ile in the general population)';
export const alcohol_circle_versus =
    '{{1}} who drinks {{2}} drinks per week ({{3}}%ile)';
export const fruit_and_vegetable_circle_rel_risk =
    '{{1}} year-old {{2}} who eats {{3}} servings of fruit and veg per day ({{4}}%ile in the general population)';
export const fruit_and_vegetable_circle_versus =
    '{{1}} who eats {{2}} servings of fruit and veg per day ({{3}}%ile)';
export const frui_juice_circle_rel_risk =
    '{{1}} year-old {{2}} who drinks {{3}} servings of fruit juice per day ({{4}}%ile in the general population)';
export const fruit_juice_circle_versus =
    '{{1}} who drinks {{2}} servings of fruit juice per day ({{3}}%ile)';
export const potatoes_circle_rel_risk =
    '{{1}} year-old {{2}} who eats {{3}} servings of potatoes per day ({{4}}%ile in the general population)';
export const potatoes_circle_versus =
    '{{1}} who eats {{2}} servings of potatoes per day ({{3}}%ile)';
export const edu_circle_versus = '{{1}} who did not complete secondary school';
export const edu_complete_post_sec_school_circle_rel_risk =
    '{{1}} year-old {{2}} who completed post-secondary school';
export const edu_some_post_sec_school_circle_rel_risk =
    '{{1}} year-old {{2}} who completed some post-secondary school';
export const edu_complete_sec_school_circle_rel_risk =
    '{{1}} year-old {{2}} who completed secondary school';
export const edu_less_sec_school_circle_rel_risk = 'Reference for education';
export const diabetes_circle_versus = '{{1}} who does not have diabetes';
export const diabetes_circle_rel_risk = '{{1}} year-old {{2}} who has diabetes';
export const hypertension_circle_versus =
    '{{1}} who does not have hypertension';
export const hypertension_circle_rel_risk =
    '{{1}} year-old {{2}} who has hypertension';
export const former_drinker_circle_rel_risk =
    '{{1}} year-old female who formerly drank alcohol';
export const former_drinker_circle_versus =
    'female person who is a current drinker';
export const physical_activity_circle_rel_risk =
    '{{1}} year old female who is active {{3}} METs per day ({{4}}%ile in the general population)';
export const physical_activity_circle_versus =
    'female who is active {{1}} METs per day ({{2}}%ile)';
export const BMI_circle_versus = '{{1}} whose BMI is {{2}}({{3}}%ile)';
export const BMI_circle_rel_risk =
    '{{1}} year-old {{2}} whose BMI is {{3}}({{4}}%ile in the general population)';
export const rel_risk_graph_x_axis =
    '5-year relative risk of cardiovascular event';
export const rel_risk_cont_graph_y_axis = 'Relative Risk';
export const rel_risk_cont_age_graph_x_axis = 'Age (years)';
export const rel_risk_cont_exp_graph_x_axis = 'Exposure (%)';
export const exposure_graph_smoking = '{{1}} pack-years';
export const exposure_graph_alcohol = '{{1}} drinks per week';
export const exposure_graph_fruit_and_veg =
    '{{1}} servings of fruit and veg per day';
export const exposure_graph_fruit_juice =
    '{{1}} servings of fruit juice per day';
export const exposure_graph_potato = '{{1}} servings of potato per day';
export const exposure_graph_constant = 'Exposure constant';
export const exposure_graph_physical_activity = '{{1}} METs per day';
