import './respect.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { SurvivalPlot } from '../../smart/SurvivalPlot';
import { BeeSwarmPlot } from '../../smart/BeeSwarmPlot';
import { IconGraph } from '../../smart/IconGraph';
import { ReactLinear } from '../../smart/RectLinear';
import { StripePlot } from '../../smart/StripePlot';
import { RelativeLinear } from '../../smart/RelativeLinear';
import { RespectControls } from '../../smart/RespectControls';
import { Card } from '@blueprintjs/core';
import { VisualizationTypes } from '../RespectControls/RespectControls';
import { GroupTableOne } from '../../smart/GroupTableOne';

export interface IRespectProps {
    activeSecondaryVisualizations: VisualizationTypes[];
}

export const Respect: React.SFC<IRespectProps> = observer(
    ({ activeSecondaryVisualizations }: IRespectProps) => {
        return (
            <div className="respect">
                <div className="respect__visualizations">
                    <SurvivalPlot />
                    {activeSecondaryVisualizations.map(
                        activeSecondaryVisualization => {
                            const GraphComponent =
                                GraphAndComponents[
                                    activeSecondaryVisualization
                                ];

                            return (
                                <GraphComponent
                                    key={activeSecondaryVisualization}
                                />
                            );
                        },
                    )}
                </div>
                <div className="respect__respect-right-side-bar-container">
                    <Card className="respect__respect-controls-container">
                        <RespectControls />
                    </Card>
                    <GroupTableOne />
                </div>
            </div>
        );
    },
);

const GraphAndComponents: {
    [visualizationName in VisualizationTypes]: React.ComponentClass
} = {
    BeeSwarm: BeeSwarmPlot,
    IconGraph: IconGraph,
    RectLinear: ReactLinear,
    RelativeLinear: RelativeLinear,
    StripePlot: StripePlot,
};
