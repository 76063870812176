export const VisualConfConfig: VisualConf = require('../../dist/data/visualConf/visual-conf.json');
export const PercentileConfig: IPercentileConfigObject[] = require('../../dist/data/percentile/cvdport-percentile.json');

const MaleAlgorithmJson = require('../../dist/data/pmml/cvdportMalePmml.json');
const FemaleAlgorithmJson = require('../../dist/data/pmml/cvdportFemalePmml.json');
export const Algorithms = {
    //@ts-ignore
    male: new PBLCalculatorEngine.constructFromAlgorithmJson(MaleAlgorithmJson),
    //@ts-ignore
    female: new PBLCalculatorEngine.constructFromAlgorithmJson(
        FemaleAlgorithmJson,
    ),
};

export interface IVisualConfObject {
    order: number;
    label: string;
    relRiskStringId?: string;
    versusRiskStringId?: string;
    referenceStringId?: string;
    name: string;
    percentileTo?: string;
    percentileFrom?: string;
    exposureGraphStringId?: string;
    input: InputAndReferenceObjects[];
    reference: InputAndReferenceObjects[];
}

export type InputAndReferenceObjects =
    | { type: 'variable'; name: string; value: string }
    | { type: 'constant'; name: string; value: number }
    | { type: 'percentile'; name: string; value: string }
    | { type: 'percentileFrom'; name: string; value: string };

export interface VisualConf {
    male: IVisualConfObject[];
    female: IVisualConfObject[];
}

interface IPercentileConfigObject {
    percentile: number;
    male: { [index: string]: number };
    female: { [index: string]: number };
}
