import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RelativeRiskGraph as RelativeRiskGraphStore } from '../../store/relative-risk-graph';
import { RelativeRiskGraph as DumbRelativeRiskGraph } from '../dumb/RelativeRiskGraph/RelativeRiskGraph';
import { Slider } from '../../store/slider';
import { RangeSlider } from '../../store/range-slider';
import { Store } from '../../store/store';
import { PrevalenceSwitch } from '../../store/prevalence-switch';
import { ContGraph } from '../../store/cont-graph';
import { getLocalizedStringForId } from '../../strings/strings';

export interface IRelativeRiskGraphProps {
    relativeRiskGraph?: RelativeRiskGraphStore;
    ageSlider?: Slider;
    exposureSlider?: RangeSlider;
    prevalenceSwitch?: PrevalenceSwitch;
    contGraph?: ContGraph;
}

@inject((stores: Store) => {
    return {
        relativeRiskGraph: stores.relativeRiskGraph,
        ageSlider: stores.ageSlider,
        exposureSlider: stores.exposureSlider,
        prevalenceSwitch: stores.prevalenceSwitch,
        contGraph: stores.contGraph,
    };
})
@observer
export class RelativeRiskGraph extends React.Component<
    IRelativeRiskGraphProps
> {
    render() {
        return (
            <DumbRelativeRiskGraph
                plotData={this.props.relativeRiskGraph!.relativeRiskGraphData}
                age={this.props.ageSlider!.currentValue}
                exposureTo={this.props.exposureSlider!.toValue}
                showPrevalence={this.props.prevalenceSwitch!.showPrevalence()}
                minRelRisk={this.props.relativeRiskGraph!.minAndMaxRelRisk[0]}
                maxRelRisk={this.props.relativeRiskGraph!.minAndMaxRelRisk[1]}
                onPredictorCircleClick={
                    this.props.contGraph!.setCurrentPredictor
                }
                xAxisLabel={getLocalizedStringForId('rel_risk_graph_x_axis')}
            />
        );
    }
}
