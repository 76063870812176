import { observable } from 'mobx';

export class InfoModalStore {
    @observable isShown: boolean = false;

    showModal = () => {
        this.isShown = true;
    };

    hideModal = () => {
        this.isShown = false;
    };
}
