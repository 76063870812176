import { observable } from 'mobx';
import { Slider } from './slider';
import { RangeSlider } from './range-slider';
import {
    VisualConfConfig,
    PercentileConfig,
    Algorithms,
    IVisualConfObject,
} from './json';
import { GenderSwitch } from './gender-switch';
import { getLocalizedStringForId } from '../strings/strings';
import { Graph } from './graph';

export class ContGraph extends Graph {
    readonly DefaultCurrentPredictorName = 'SmokeCurrent_xc';

    //Set in constructor using setCurrentPredictor method
    @observable
    currentPredictor!: IVisualConfObject;

    constructor(
        ageSlider: Slider,
        exposureSlider: RangeSlider,
        genderSwitch: GenderSwitch,
    ) {
        super(ageSlider, exposureSlider, genderSwitch);

        this.setCurrentPredictor(this.DefaultCurrentPredictorName);
    }

    getContGraphData(
        varyBy: 'age' | 'exposureTo',
        predictorLabel: string,
        minX: number,
        maxX: number,
    ) {
        var RRdata = [],
            index = [],
            percentileExp_to = [];
        const age = this.ageSlider.currentValue;
        const gender = this.genderSwitch.getCurrentGender();
        const exposureTo = this.exposureSlider.toValue;
        const algorithmForCurrentGender =
            Algorithms[this.genderSwitch.getCurrentGender()];
        // varyBy = age or exp_to
        // global variables used in this function: age, exp_to, exp_from, as well as plotPredictors object.

        // temporally store global value. Re-assign global to temp value at end of function. The use this[].
        let temp = this.ageSlider.currentValue;
        if (varyBy === 'exposureTo') {
            temp = this.exposureSlider.toValue;
        }

        const predictor = this.currentPredictor;

        for (let i = minX; i < maxX + 1; i++) {
            const ageToUse = varyBy === 'age' ? i - 1 : age;
            const exposureToToUse =
                varyBy === 'exposureTo' ? i - 1 : exposureTo;

            var reference = [],
                input = [];

            for (let j = 0; j < predictor.reference.length; j++) {
                reference.push(
                    this.getDatumForInputOrReferenceObject(
                        predictor.reference[j],
                        {
                            age: ageToUse,
                            exposureTo: exposureToToUse,
                        },
                    ),
                );
                input.push(
                    this.getDatumForInputOrReferenceObject(predictor.input[j], {
                        age: ageToUse,
                        exposureTo: exposureToToUse,
                    }),
                );
            }

            var inputRisk = algorithmForCurrentGender.evaluate(input);
            var referenceRisk = algorithmForCurrentGender.evaluate(reference);

            RRdata.push(inputRisk / referenceRisk);
            index.push(i);
            percentileExp_to.push(
                PercentileConfig[exposureTo][gender][
                    predictor.percentileTo as string
                ],
            );
        }

        // index is just for testing. Can remove everthing is working.
        return {
            RRdata: RRdata,
            percentileExp_to: percentileExp_to,
            label: predictorLabel,
            varyBy: varyBy,
            varyByLabel: predictor.exposureGraphStringId
                ? getLocalizedStringForId(
                      predictor.exposureGraphStringId as any,
                      [Math.round(percentileExp_to[temp - minX])],
                  )
                : '',
        };
    }

    setCurrentPredictor = (predictorName: string) => {
        this.currentPredictor = VisualConfConfig[
            this.genderSwitch.getCurrentGender()
        ].find(predictor => {
            return predictor.name === predictorName;
        }) as IVisualConfObject;
    };
}
