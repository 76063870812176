import './mport-page.scss';

import * as React from 'react';
import { AlgorithmPage } from '../AlgorithmPage/AlgorithmPage';
import { MPoRTRelativeRiskGraph } from '../../smart/MPoRTRelativeRiskGraph';
import { MPoRTAlgorithmPageControls } from '../../smart/MPoRTAlgorithmPageControls';
import { observer } from 'mobx-react';
import { MPoRTAgeContGraph } from '../../smart/MPoRTAgeContGraph';
import { MPoRTExposureContGraph } from '../../smart/MPoRTExposureContGraph';

export const MPoRTPage = observer(() => {
    return (
        <AlgorithmPage
            className="mport-page"
            title="MPoRT"
            RelativeRiskGraph={<MPoRTRelativeRiskGraph />}
            AgeContGraph={<MPoRTAgeContGraph />}
            ExposureContGraph={<MPoRTExposureContGraph />}
            AlgorithmPageControls={<MPoRTAlgorithmPageControls />}
        />
    );
});
