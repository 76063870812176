import './respect-app.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Respect } from '../../smart/Respect';

export interface IRespectAppProps {
    isLoading: boolean;
}

export const RespectApp: React.SFC<IRespectAppProps> = observer(
    ({ isLoading }: IRespectAppProps) => {
        return (
            <div className="respect-app">
                {isLoading ? <div>Loading</div> : <Respect />}
            </div>
        );
    },
);
