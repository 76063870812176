import { observable } from 'mobx';
import { ConfigSingleton } from './config-singleton';
import { Model } from '@ottawamhealth/pbl-calculator-engine/lib/engine/model/model';
import { CoxSurvivalAlgorithm } from '@ottawamhealth/pbl-calculator-engine/lib/engine/algorithm/regression-algorithm/cox-survival-algorithm/cox-survival-algorithm';
import { Tabel1 } from '../../models/tabel-1';
import { WebSpec } from '../../models/labels';
const RespectTable1: Tabel1 = require('../../../dist/data/respect/table1/table1.json');
const RespectLabels = require('../../../dist/data/respect/web-spec.json');
const RespectCategories = require('../../../dist/data/respect/categories.json');
const RespectBinPercentiles = require('../../../dist/data/respect/bin-percentile.json');
import { autobind } from 'core-decorators';
import { Categories } from '../../models/categories';
import { BinPercentiles } from '../../models/bin-percentiles';

@autobind
export class ModelsSingleton {
    @observable private _respect?: Model;
    respectTable1: Tabel1;
    respectWebSpec: WebSpec;
    respectCategories: Categories;
    respectBinPercentiles: BinPercentiles;

    constructor() {
        this.respectTable1 = RespectTable1;
        this.respectWebSpec = RespectLabels;
        this.respectCategories = RespectCategories;
        this.respectBinPercentiles = RespectBinPercentiles;
    }

    fetchModels = () => {
        fetch(`${window.location.protocol}/${ConfigSingleton.models.respect}`)
            .then(res => {
                return res.json();
            })
            .then(modelJson => {
                this._respect = new Model(modelJson);
            });
    };

    get respect(): CoxSurvivalAlgorithm | false {
        if (this._respect) {
            return this._respect.algorithms[0]
                .algorithm as CoxSurvivalAlgorithm;
        } else {
            return false;
        }
    }

    get groupNames(): string[] {
        return this.respectWebSpec.reduce(
            (groupNames: string[], currentRespectLabelObj) => {
                if (groupNames.indexOf(currentRespectLabelObj.Type) > -1) {
                    return groupNames;
                } else {
                    return groupNames.concat(currentRespectLabelObj.Type);
                }
            },
            [],
        );
    }
}

export const modelsSingleton = new ModelsSingleton();
modelsSingleton.fetchModels();
