export enum Visualizations {
    BeeSwarm,
    IconGraph,
    RectLinear,
    StripePlot,
    RelativeLinear,
}

export function getVisualizationNameFromVisualization(
    visualization: Visualizations,
): 'BeeSwarm' | 'IconGraph' | 'RectLinear' | 'StripePlot' | 'RelativeLinear' {
    switch (visualization) {
        case Visualizations.BeeSwarm: {
            return 'BeeSwarm';
        }
        case Visualizations.IconGraph: {
            return 'IconGraph';
        }
        case Visualizations.RectLinear: {
            return 'RectLinear';
        }
        case Visualizations.RelativeLinear: {
            return 'RelativeLinear';
        }
        case Visualizations.StripePlot: {
            return 'StripePlot';
        }
    }
}
