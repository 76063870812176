import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { StripePlot as DumbStripePlot } from '../dumb/StripePlot/StripePlot';
import { Respect } from '../../store/ui/respect/respect';

export interface IStripePlotProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class StripePlot extends React.Component<IStripePlotProps> {
    render() {
        const {
            stripePlotData,
            medianSurvivalForSelectedBin,
        } = this.props.respect!;

        return (
            <DumbStripePlot
                data={stripePlotData}
                medianSurvival={medianSurvivalForSelectedBin / 365}
            />
        );
    }
}
