import { observable } from 'mobx';
import { InfoModalStore } from './info_modal';
import { Slider } from './slider';
import { RangeSlider } from './range-slider';
import { GenderSwitch } from './gender-switch';
import { PrevalenceSwitch } from './prevalence-switch';
import { RelativeRiskGraph } from './relative-risk-graph';
import { ContGraph } from './cont-graph';
import { AlgorithmSelectorPage } from './ui/algorithm-selector-page';
import { Respect } from './ui/respect/respect';
import { MPoRTPage, mportPageSingleton } from './ui/mport-page';

export class Store {
    @observable
    infoModal = new InfoModalStore();
    @observable
    genderSwitch = new GenderSwitch();
    @observable
    prevalenceSwitch = new PrevalenceSwitch();
    @observable
    ageSlider = new Slider(40);
    @observable
    exposureSlider = new RangeSlider(10, 75);
    @observable
    relativeRiskGraph: RelativeRiskGraph;
    @observable
    contGraph: ContGraph;
    @observable
    algorithmSelectorPage: AlgorithmSelectorPage;
    @observable
    respect: Respect;
    @observable
    mport: MPoRTPage;

    constructor() {
        this.infoModal.isShown = true;
        this.relativeRiskGraph = new RelativeRiskGraph(
            this.ageSlider,
            this.exposureSlider,
            this.genderSwitch,
            this.prevalenceSwitch,
        );
        this.contGraph = new ContGraph(
            this.ageSlider,
            this.exposureSlider,
            this.genderSwitch,
        );
        this.algorithmSelectorPage = new AlgorithmSelectorPage();
        this.respect = new Respect();
        this.mport = mportPageSingleton;
    }
}

export const store = new Store();
