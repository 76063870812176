import './slider-control.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Slider, ISliderProps } from '@blueprintjs/core';
import * as classNames from 'classnames';

export interface ISliderControlProps {
    className?: string;
    label: string;
    sliderProps: ISliderProps;
}

export const SliderControl: React.SFC<ISliderControlProps> = observer(
    ({ className, label, sliderProps }: ISliderControlProps) => {
        return (
            <div className={classNames('slider-control', className)}>
                <h3 className="algorithm-page-controls__slider-title">
                    {label}
                </h3>
                <Slider {...sliderProps} />
            </div>
        );
    },
);
