import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MPoRTPage } from '../../store/ui/mport-page';
import { ContGraph } from '../dumb/ContGraph/ContGraph';
import { min, max } from 'lodash';
import { getLocalizedStringForId } from '../../strings/strings';
import { computed } from 'mobx';

export interface MPoRTExposureContGraphProps {
    mport?: MPoRTPage;
}

@inject('mport')
@observer
export class MPoRTExposureContGraph extends React.Component<
    MPoRTExposureContGraphProps
> {
    @computed
    get RRData() {
        const mportPage = this.props.mport!;
        const controls = mportPage.controls;

        const rrData: number[] = [];
        for (
            let currentExposure = 10;
            currentExposure <= 90;
            currentExposure++
        ) {
            rrData.push(
                MPoRTPage.mportSingleton.getRelativeRiskForPredictor(
                    mportPage.currentPredictor,
                    controls.sexSwitch.getCurrentGenderForEngine(),
                    controls.ageSlider.currentValue,
                    currentExposure,
                    currentExposure,
                ),
            );
        }

        return rrData;
    }
    render() {
        const rrData = this.RRData;

        return (
            <ContGraph
                minRR={min(rrData) as number}
                maxRR={max(rrData) as number}
                minX={10}
                RRdata={rrData}
                label=""
                xLabel={getLocalizedStringForId(
                    'rel_risk_cont_exp_graph_x_axis',
                )}
                currentValue={this.props.mport!.controls.exposureSlider.toValue}
                varyByLabel="Exposure"
            />
        );
    }
}
