import { HashRouter, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';
import * as React from 'react';
import { store } from '../../store/store';
import { AlgorithmSelectorPage } from './AlgorithmSelectorPage';
import { RespectApp } from './RespectApp';
import { AlgorithmViewerApp as DumbAlgorithmViewerApp } from '../dumb/AlgorithmViewerApp/AlgorithmViewerApp';
import { CVDPoRTPage } from '../dumb/CVDPoRTPage/CVDPoRTPage';
import { MPoRTPage } from '../dumb/MPoRTPage/MPoRTPage';

export class AlgorithmViewerApp extends React.Component {
    render() {
        return (
            <Provider {...store}>
                {/*Use hash router since normal router does not work on gh-pages*/}
                <HashRouter>
                    <DumbAlgorithmViewerApp>
                        <Route
                            exact
                            path="/"
                            component={AlgorithmSelectorPage}
                        />
                        <Route
                            exact
                            path="/cvdport-r"
                            component={CVDPoRTPage}
                        />
                        <Route exact path="/mportv2" component={MPoRTPage} />
                        <Route exact path="/respect" component={RespectApp} />
                    </DumbAlgorithmViewerApp>
                </HashRouter>
            </Provider>
        );
    }
}
