import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ContGraph } from '../../store/cont-graph';
import { ContGraph as DumbContGraph } from '../dumb/ContGraph/ContGraph';
import { Store } from '../../store/store';
import { RelativeRiskGraph } from '../../store/relative-risk-graph';
import { getLocalizedStringForId } from '../../strings/strings';

export interface IExposureContGraphProps {
    contGraph?: ContGraph;
    relativeRiskGraph?: RelativeRiskGraph;
    className: string;
}

@inject((stores: Store) => {
    return {
        contGraph: stores.contGraph,
        relativeRiskGraph: stores.relativeRiskGraph,
    };
})
@observer
export class ExposureContGraph extends React.Component<
    IExposureContGraphProps
> {
    render() {
        return (
            <DumbContGraph
                {...this.props.contGraph!.getContGraphData(
                    'exposureTo',
                    '',
                    10,
                    90,
                )}
                minRR={this.props.relativeRiskGraph!.minAndMaxRelRisk[0]}
                maxRR={this.props.relativeRiskGraph!.minAndMaxRelRisk[1]}
                minX={10}
                xLabel={getLocalizedStringForId(
                    'rel_risk_cont_exp_graph_x_axis',
                )}
                currentValue={this.props.contGraph!.exposureSlider.toValue}
                className={this.props.className}
            />
        );
    }
}
