import * as React from 'react';
import { render } from 'react-dom';
import { AlgorithmViewerApp } from './components/smart/AlgorithmViewerApp';

document.addEventListener(
    'DOMContentLoaded',
    function() {
        render(<AlgorithmViewerApp />, document.getElementById('react-app'));
    },
    false,
);
