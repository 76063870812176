import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { IconGraph as DumbIconGraph } from '../dumb/IconGraph/IconGraph';
import { Respect } from '../../store/ui/respect/respect';

export interface IIconGraphProps {
    respect?: Respect;
}

@inject('respect')
@observer
export class IconGraph extends React.Component<IIconGraphProps> {
    render() {
        const { iconGraphSurvivalPercent } = this.props.respect!;

        return <DumbIconGraph survival={iconGraphSurvivalPercent} />;
    }
}
