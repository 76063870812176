import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ContGraph } from '../dumb/ContGraph/ContGraph';
import { getLocalizedStringForId } from '../../strings/strings';
import { min, max } from 'lodash';
import { MPoRTPage } from '../../store/ui/mport-page';
import { computed } from 'mobx';

export interface MPoRTAgeContGraphProps {
    mport?: MPoRTPage;
}

@inject('mport')
@observer
export class MPoRTAgeContGraph extends React.Component<MPoRTAgeContGraphProps> {
    @computed
    get contGraphData() {
        const mportPage = this.props.mport!;

        const controls = mportPage.controls;
        const exposureSlider = controls.exposureSlider;

        const minAge = 20;
        const maxAge = 90;

        const rrData: number[] = [];
        for (let currentAge = minAge; currentAge <= maxAge; currentAge++) {
            rrData.push(
                MPoRTPage.mportSingleton.getRelativeRiskForPredictor(
                    mportPage!.currentPredictor,
                    controls.sexSwitch.getCurrentGenderForEngine(),
                    currentAge,
                    exposureSlider.toValue,
                    exposureSlider.fromValue,
                ),
            );
        }

        return rrData;
    }
    render() {
        const controls = this.props.mport!.controls;

        const rrData = this.contGraphData;

        return (
            <ContGraph
                label={
                    MPoRTPage.mportSingleton.findGeneralSpecForPredictor(
                        this.props.mport!.currentPredictor,
                    ).displayName
                }
                RRdata={this.contGraphData}
                minRR={min(rrData) as number}
                maxRR={max(rrData) as number}
                minX={20}
                xLabel={getLocalizedStringForId(
                    'rel_risk_cont_age_graph_x_axis',
                )}
                currentValue={controls.ageSlider.currentValue}
                varyByLabel=""
                className="mport-cont-graph"
            />
        );
    }
}
