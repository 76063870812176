import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Switch } from '../../store/switch';
import { Slider } from '../../store/slider';
import { RangeSlider } from '../../store/range-slider';
import { Store } from '../../store/store';
import { AlgorithmPageControls as DumbAlgorithmPageControls } from '../dumb/AlgorithmPageControls/AlgorithmPageControls';

export interface IAlgorithmPageControlsProps {
    genderSwitch?: Switch;
    prevalenceSwitch?: Switch;
    ageSlider?: Slider;
    exposureSlider?: RangeSlider;
}

@inject((stores: Store) => {
    return {
        genderSwitch: stores.genderSwitch,
        prevalenceSwitch: stores.prevalenceSwitch,
        ageSlider: stores.ageSlider,
        exposureSlider: stores.exposureSlider,
    };
})
@observer
export class AlgorithmPageControls extends React.Component<
    IAlgorithmPageControlsProps
> {
    render() {
        return (
            <DumbAlgorithmPageControls
                onAgeSliderChange={this.props.ageSlider!.setCurrentValue}
                currentAge={this.props.ageSlider!.currentValue}
                currentExposureFrom={this.props.exposureSlider!.fromValue}
                currentExposureTo={this.props.exposureSlider!.toValue}
                onExposureSliderChange={this.props.exposureSlider!.updateValues}
                isGenderSwitchChecked={this.props.genderSwitch!.isChecked}
                onGenderSwitchToggle={this.props.genderSwitch!.toggleSwitch}
                isPrevalenceSwitchChecked={
                    this.props.prevalenceSwitch!.isChecked
                }
                onPrevalenceSwitchToggle={
                    this.props.prevalenceSwitch!.toggleSwitch
                }
            />
        );
    }
}
