import { InputAndReferenceObjects, PercentileConfig } from './json';
import { RangeSlider } from './range-slider';
import { Slider } from './slider';
import { GenderSwitch } from './gender-switch';

export class Graph {
    ageSlider: Slider;
    exposureSlider: RangeSlider;
    genderSwitch: GenderSwitch;

    constructor(
        ageSlider: Slider,
        exposureSlider: RangeSlider,
        genderSwitch: GenderSwitch,
    ) {
        this.ageSlider = ageSlider;
        this.exposureSlider = exposureSlider;
        this.genderSwitch = genderSwitch;
    }

    getDatumForInputOrReferenceObject = (
        refObj: InputAndReferenceObjects,
        values?: {
            age?: number;
            exposureFrom?: number;
            exposureTo?: number;
        },
    ): {
        name: string;
        coefficent: number;
    } => {
        const valuesToUse = Object.assign(
            {},
            {
                age: this.ageSlider.currentValue,
                exposureFrom: this.exposureSlider.fromValue,
                exposureTo: this.exposureSlider.toValue,
            },
            values,
        );

        const currentGender = this.genderSwitch.getCurrentGender();

        if (refObj.type === 'variable') {
            return {
                name: refObj.name,
                coefficent: valuesToUse.age,
            };
        } else if (refObj.type === 'constant') {
            return {
                name: refObj.name,
                coefficent: refObj.value,
            };
        } else if (refObj.type === 'percentile') {
            return {
                name: refObj.name,
                coefficent:
                    PercentileConfig[valuesToUse.exposureTo][currentGender][
                        refObj.value
                    ],
            };
        } else {
            return {
                name: refObj.name,
                coefficent:
                    PercentileConfig[valuesToUse.exposureFrom][currentGender][
                        refObj.value
                    ],
            };
        }
    };
}
