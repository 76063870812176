import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'react-bootstrap';
import { getLocalizedStringForId } from '../../../strings/strings';

export interface IInfoModalProps {
    isShown: boolean;
    onHideModal: () => void;
}

export const InfoModal: React.SFC<IInfoModalProps> = observer(
    ({ isShown, onHideModal }: IInfoModalProps) => {
        return (
            <Modal show={isShown} onHide={onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {getLocalizedStringForId('modal_title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    dangerouslySetInnerHTML={{
                        __html: getLocalizedStringForId('modal_description'),
                    }}
                />
                <Modal.Footer>
                    <Button onClick={onHideModal}>
                        {getLocalizedStringForId('modal_continue_button')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    },
);
