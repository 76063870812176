import './cont-graph.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import * as d3 from 'd3';
import * as classNames from 'classnames';

export interface IContGraphProps {
    minRR: number;
    maxRR: number;
    minX: number;
    RRdata: number[];
    label: string;
    xLabel: string;
    // Age or exposureTo
    currentValue: number;
    varyByLabel: string;
    className?: string;
}

@observer
export class ContGraph extends React.Component<IContGraphProps> {
    containerRef!: HTMLDivElement;
    updateGraph!: (newProps: IContGraphProps) => void;
    componentDidMount() {
        this.updateGraph = renderContGraph(this.containerRef, this.props);
    }
    componentDidUpdate() {
        return this.updateGraph(this.props);
    }
    render() {
        return (
            <div
                className={classNames('cont-graph', this.props.className)}
                ref={ref => {
                    this.containerRef = ref as HTMLDivElement;
                }}
            />
        );
    }
}

function renderContGraph(
    rootNode: HTMLElement,
    {
        minRR,
        maxRR,
        minX,
        RRdata,
        label,
        xLabel,
        currentValue,
        varyByLabel,
    }: IContGraphProps,
) {
    // To do: put in an config object.
    var width = 340;
    var height = 180;

    var margin = { right: 40, left: 40, top: 30, bottom: 20 },
        width = width - margin.left - margin.right,
        height = height - margin.top - margin.top;

    // Define scales and domain
    var xScale = d3.scaleLinear().range([0, width]);
    var yScale = d3.scaleLog().range([height, 0]);

    xScale.domain([0, 100]);
    yScale.domain([minRR > 0.5 ? 0.5 : minRR, maxRR < 6 ? 6 : maxRR]);

    // Define and append SVG
    var dom = d3.select(rootNode);
    var svg = dom
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Define line
    const line = d3
        .line()
        .curve(d3.curveBasis)
        .x(function(d, i) {
            d;
            return xScale(i + minX);
        })
        .y(function(d) {
            // @ts-ignore
            return yScale(d);
        });

    d3.select('body')
        .append('div')
        .attr('class', 'circleAnnotate');

    // Define svg and initialize visualization
    svg.append('g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,' + height + ')');

    const yAxis = svg.append('g').attr('class', 'y axis');

    svg.append('path')
        .attr('class', 'line')
        .attr('id', 'Line')
        //  .data([data.RRdata])
        .enter()
        .append('g');

    // add plot title
    const plotTitle = svg
        .append('text')
        .attr('y', margin.top - 20)
        .attr('x', xScale(30))
        .attr('id', 'label')
        .style('font-size', '1.2em')
        .text(label);

    // Add the axes
    //      svg.select('.x.axis')
    //       .call(d3.axisBottom(xScale).tickValues([20,80]));

    // add X-axis label
    svg.append('text')
        .attr('y', height + 10)
        .attr('x', xScale(45))
        .style('font-size', '0.9em')
        .text(xLabel)
        .style('text-anchor', 'middle');

    // add y-axis label
    svg.append('text')
        .attr('y', 10 - margin.left)
        .attr('x', 0 - height / 2)
        .style('font-size', '0.9em')
        .text('Relative Risk')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle');

    //@ts-ignore
    yAxis.call(d3.axisLeft(yScale).ticks(6, ',.2'));

    // Add the rr= 1 line
    svg.append('line')
        .attr('class', 'axis')
        .style('stroke', 'Silver')
        .attr('z-index', '.1')
        .attr('x1', xScale(0))
        .attr('y1', yScale(1))
        .attr('x2', xScale(100))
        .attr('y2', yScale(1));

    var xCircle = xScale(currentValue);
    var yCircle = yScale(RRdata[currentValue - minX]);

    // Add x-axis value for circle
    const xAxisText = svg
        .append('text')
        .attr('x', xCircle)
        .attr('y', yScale(1) + 15)
        .text(currentValue)
        .style('text-anchor', 'middle')
        .attr('id', 'xAxisLabel');

    // Add the line from circle to xAxis
    const circleToXAxisLine = svg
        .append('line')
        .attr('id', 'circleToX')
        .style('stroke', 'Silver')
        .attr('x1', xCircle)
        .attr('y1', yCircle + 8)
        .attr('x2', xCircle)
        .attr('y2', yScale(1));

    // Add circle for selected prevalence
    const circle = svg
        .append('circle')
        .attr('id', 'PlotVaryBy')
        .attr('r', 8)
        .attr('cx', xCircle)
        .attr('cy', yCircle)
        .attr('class', 'cont-graph__circle');

    //Add annotation  -- TODO: clean this code up.
    function getVaryByLabel() {
        return varyByLabel;
    }

    const varyByText = svg
        .append('text')
        .attr('x', xCircle)
        .attr('y', Math.min(yCircle - 15, yScale(1.1)))
        .text(getVaryByLabel())
        .style('text-anchor', 'middle')
        .attr('id', 'circleAnnotate');
    // add line

    const graphLine = svg
        .select('#Line')
        // @ts-ignore
        .attr('d', line(RRdata));

    /*chart.config = function(value) {
        if (!arguments.length) return config;
        config = value;
        if (typeof updateConfig === 'function') updateConfig();
        return chart;
    };

    chart.label = function(value) {
        if (!arguments.length) return label;
        label = value;
        if (typeof updateLabel === 'function') updateLabel();
        return chart;
    };

    chart.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        if (typeof updateWidth === 'function') updateWidth();
        console.log('chart width');
        return chart;
    };

    chart.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        if (typeof updateHeight === 'function') updateHeight();
        return chart;
    };

    chart.varyBy = function(value) {
        if (!arguments.length) return varyBy;
        varyBy = value;
        if (typeof updateVaryBy === 'function') updateVaryBy();
        return chart;
    };

    chart.data = function(value) {
        if (!arguments.length) return data;
        data = value;
        if (typeof updateData === 'function') updateData();
        return chart;
    };*/

    //return chart;

    return function update({
        RRdata,
        currentValue,
        minRR,
        maxRR,
        label,
        varyByLabel,
    }: IContGraphProps) {
        var yScale = d3.scaleLog().range([height, 0]);
        yScale.domain([minRR > 0.5 ? 0.5 : minRR, maxRR < 6 ? 6 : maxRR]);
        //@ts-ignore
        yAxis.call(d3.axisLeft(yScale).ticks(6, ',.2'));

        const line = d3
            .line()
            .curve(d3.curveBasis)
            .x(function(d, i) {
                d;

                return xScale(i + minX);
            })
            .y(function(d) {
                return yScale(d as any);
            });

        //@ts-ignore
        graphLine.attr('d', line(RRdata));

        var xCircle = xScale(currentValue);
        var yCircle = yScale(RRdata[currentValue - minX]);
        circle.attr('cx', xCircle).attr('cy', yCircle);

        circleToXAxisLine
            .attr('x1', xCircle)
            .attr('y1', yCircle + 8)
            .attr('x2', xCircle)
            .attr('y2', yScale(1));

        xAxisText
            .attr('x', xCircle)
            .attr('y', yScale(1) + 15)
            .text(currentValue);

        plotTitle.text(label);
        varyByText.text(varyByLabel);
    };
}
