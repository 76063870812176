import './algorithm-selector-page.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import * as classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface IAlgorithmSelectorPageProps {
    models: Array<{
        name: string;
        isLoading: boolean;
    }>;
}

export const AlgorithmSelectorPage: React.SFC<
    IAlgorithmSelectorPageProps
> = observer(({ models }: IAlgorithmSelectorPageProps) => {
    return (
        <div className="model-selector-page">
            {models.map((currentModel, index) => {
                return (
                    <Link
                        key={index}
                        className={classNames(
                            'model-selector-page__model-name-container',
                            'model-selector-page__algorithm-link',
                            {
                                'pt-skeleton': currentModel.isLoading,
                            },
                        )}
                        to={`/${currentModel.name}`}
                    >
                        {currentModel.name}
                    </Link>
                );
            })}
        </div>
    );
});
